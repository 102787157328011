/**
 * Card
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 * @author Johannes Pommranz <johannes.pommranz@zandura.com>
 */

import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Card
 * ====
 *
 * + handle hover, focus & active states
 * + handle click & keydown
 *
 * Events
 * ------
 *
 * zui:card:click { value: <[data-zui-value]>, $el: Element.zui-card }
 *
 */
export default class Card {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.$el = element;
    this.$actions = Array.from(document.querySelectorAll([
      'button',
      '[href]',
      'input',
      'select',
      'textarea',
      '[role=button]',
      '[tabindex]:not([tabindex="-1"])',
      '.scroll', '.scroll-x', '.scroll-y', // overflow: auto/scroll makes Elements focusable in FF
    ].map(t => `#${zuiIdentify(this.$el)} ${t}`).join(', ')))
      // check if its direct child
      .filter((el) => {
        let parent = el;
        do parent = parent.parentElement;
        while (parent && !parent.classList.contains('zui-card'));
        return parent === this.$el;
      });

    this.$effectTimeout = 420;

    this.initEventListeners();

    this.$el.classList.add('updated');
  }

  initEventListeners() {

    this.$el.addEventListener('click', e => this.onClick(e));
    this.$el.addEventListener('focusout', e => this.onFocusout(e));
    this.$el.addEventListener('focusin', e => this.onFocusin(e));
    this.$el.addEventListener('keydown', e => this.onKeydown(e));
    this.$el.addEventListener('mousedown', e => this.onMousedown(e));
    this.$el.addEventListener('mouseout', e => this.onMouseout(e));
    this.$el.addEventListener('mouseover', e => this.onMouseover(e));
  }

  /**
   * @param {Event} event
   */
  onClick(event) {
    if (event.target.classList.contains('card-action') && this.$actions.find(item => item === event.target)) {
      zuiDispatchEvent(this.$el, 'zui:card:click', {
        $el: this.$el,
        value: this.$el.getAttribute('data-zui-value'),
      });
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    if (event.target.classList.contains('card-action')
      && (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar')
    ) {
      event.preventDefault();
      this.onMousedown(event);
      this.onClick(event);
    }
  }

  /**
   * @param {Event} event
   */
  onMousedown(event) {
    if (event.target.classList.contains('card-action') && this.$actions.find(item => item === event.target)) {
      this.$el.classList.add('active');
      setTimeout(() => this.$el.classList.remove('active'), this.$effectTimeout);
    }
  }

  /**
   * @param {MouseEvent} event
   */
  onMouseout(event) {
    if (event.target.classList.contains('card-action') && this.$actions.find(item => item === event.target)) this.$el.classList.remove('hovered');
  }

  /**
   * @param {MouseEvent} event
   */
  onMouseover(event) {
    if (event.target.classList.contains('card-action') && this.$actions.find(item => item === event.target)) this.$el.classList.add('hovered');
  }

  /**
   * @param {Event} event
   */
  onFocusout() {
    this.$el.classList.remove('focused');
  }

  /**
   * @param {Event} event
   */
  onFocusin(event) {
    if (this.$actions.find(item => item === event.target)) this.$el.classList.add('focused');
  }
}


/**
 * @param {HTMLElement} element
 * @param {Card} element.zuiCard
 * @constructor
 * @return Card;
 */
export function CardFactory(element) {
  if (element.zuiCard === undefined) {
    Object.defineProperty(element, 'zuiCard', {
      enumerable: false,
      writable: false,
      value: new Card(element),
    });
  }
  return element.zuiCard;
}
