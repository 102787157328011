/**
 * SelectableList
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */
import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * SelectableList
 * ==============
 *
 * provide basic methods for selectable lists
 *
 */
export default class SelectableList {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    // selector for child items
    this.$itemSelector = `#${zuiIdentify(this.$el)} .item`;

    // attribute to toggle on selection
    this.$selectedAttribute = 'aria-selected';
    // de-/selected values: {boolean} false means, the attribute has du be removed for this case
    this.$selectedValue = 'true';
    this.$deselectedValue = false;

    // selector for the parent element, if we have to check parent-ship (nexted elements only)
    this.$nestedCheckParentSelector = false;

    this.$multiselectable = false;
    this.$required = true;

    this.$eventPrefix = 'zui:selectable-list';
  }

  /**
   * @param {boolean} enabled
   * @param {boolean} selected
   * @return {HTMLElement[]}
   */
  getItems(enabled = undefined, selected = undefined) {
    let items = Array.from(this.$el.querySelectorAll(this.$itemSelector));
    if (this.$nestedCheckParentSelector) {
      items = items.filter((el) => {
        let parent = el;
        do parent = parent.parentElement;
        while (parent && !parent.matches(this.$nestedCheckParentSelector));
        return parent === this.$el;
      });
    }
    if (selected === true) {
      items.filter(el => this.isSelected(el));
    } else if (selected === false) {
      items.filter(el => !this.isSelected(el));
    }
    if (enabled === true) {
      items.filter(el => this.isEnabled(el));
    } else if (enabled === false) {
      items.filter(el => !this.isEnabled(el));
    }
    return items;
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  getItem(element) {
    return Number.isInteger(element)
      ? (this.getItems()[element] || undefined)
      : this.getItems().find(item => item === element || item.contains(element));
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  select(element) {

    const selectItem = this.getItem(element);
    const control = selectItem ? document.querySelector(`#${zuiIdentify(selectItem)} [aria-controls]`) : undefined;

    if (selectItem) {
      if (this.$selectedValue) {
        selectItem.setAttribute(this.$selectedAttribute, this.$selectedValue);
      } else {
        selectItem.removeAttribute(this.$selectedAttribute);
      }
      if (!this.isMultiselectable()) {
        this.getItems()
          .filter(item => item !== selectItem)
          .forEach(item => this.deselect(item));
      }
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:select`, {
        $el: selectItem,
        value: selectItem.getAttribute('data-zui-value'),
        ariaControls: control ? control.getAttribute('aria-controls').split(' ') : undefined,
      });
    }
    return selectItem;
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  deselect(element) {

    const deselectItem = this.getItem(element);
    const control = deselectItem ? document.querySelector(`#${zuiIdentify(deselectItem)} [aria-controls]`) : undefined;

    if (deselectItem) {
      if (!this.isRequired() || this.getItems()
        .filter(item => item !== deselectItem)
        .find(item => item.getAttribute(this.$selectedAttribute) === this.$selectedValue)
      ) {
        if (this.$deselectedValue) {
          deselectItem.setAttribute(this.$selectedAttribute, this.$deselectedValue);
        } else {
          deselectItem.removeAttribute(this.$selectedAttribute);
        }
        zuiDispatchEvent(this.$el, `${this.$eventPrefix}:deselect`, {
          $el: deselectItem,
          value: deselectItem.getAttribute('data-zui-value'),
          ariaControls: control ? control.getAttribute('aria-controls').split(' ') : undefined,
        });
      }
    }
    return deselectItem;
  }

  /**
   * @return {HTMLElement}
   * @param element
   */
  isSelected(element) {

    const item = this.getItem(element);
    let result = null;

    if (item) {
      result = this.$selectedValue
        ? item.getAttribute(this.$selectedAttribute) === this.$selectedValue
        : !item.hasAttribute(this.$selectedValue);
    }
    return result;
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  enable(element) {

    const item = this.getItem(element);
    const control = item ? document.querySelector(`#${zuiIdentify(item)} [aria-controls]`) : undefined;

    if (item) {
      if (item.disabled === undefined) {
        item.removeAttribute('aria-disabled');
      } else {
        item.disabled = false;
      }
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:enable`, {
        $el: item,
        value: item.getAttribute('data-zui-value'),
        ariaControls: control ? control.getAttribute('aria-controls').split(' ') : undefined,
      });
    }
    return item;
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  disable(element) {

    const item = this.getItem(element);
    const control = item ? document.querySelector(`#${zuiIdentify(item)} [aria-controls]`) : undefined;

    if (item) {
      if (item.disabled === undefined) {
        item.setAttribute('aria-disabled', 'true');
      } else {
        item.disabled = true;
      }
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:disable`, {
        $el: item,
        value: item.getAttribute('data-zui-value'),
        ariaControls: control ? control.getAttribute('aria-controls').split(' ') : undefined,
      });
    }
    return item;
  }

  isEnabled(element) {

    const item = this.getItem(element);
    let result = null;

    if (item) {
      result = item.disabled === undefined ? item.getAttribute('aria-disabled') === true : !item.disabled;
    }

    return result;
  }

  /**
   * Check or set if multiple elements may be selected at once
   *
   * The value can be configured via this.$multiselectable
   *
   * The Method may replaced by this.$el.hasAttribute('aria-multiselectable');
   *
   * @param {boolean} value
   * @return {boolean}
   */
  isMultiselectable(value = undefined) {
    if (value !== undefined) {
      this.$multiselectable = !!value;
    }
    return this.$multiselectable;
  }

  /**
   * Check or set if at least one element has to be selected
   *
   * The value can be configured via this.$multiselectable
   *
   * The Method may be replaced by this.$el.hasAttribute('aria-required);
   *
   * @return {boolean}
   */
  isRequired(value = undefined) {
    if (value !== undefined) {
      this.$required = !!value;
    }
    return this.$required;
  }
}
