/**
 * Option
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import Toggleable from './toggleable';

/**
 * Option
 *
 * @see Toggleable
 */
export default class Option extends Toggleable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    // setup statusAttributeName
    this.$statusAttributeName = 'aria-selected';

    this.$eventPrefix = 'zui:option';
  }
}

/**
 * @param {HTMLElement} element
 * @param {Option} element.zuiOption  initiated Option
 * @constructor
 * @return Option;
 */
export function OptionFactory(element) {
  if (element.zuiOption === undefined) {
    Object.defineProperty(element, 'zuiOption', {
      enumerable: false,
      writable: false,
      value: new Option(element),
    });
  }
  return element.zuiOption;
}
