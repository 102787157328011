/**
 * Drawer
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 * @author Johannes Pommranz <johannes.pommranz@zandura.net>
 */

import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Drawer
 * ======
 *
 * Methods
 * -------
 *
 * show()
 * hide()
 * status()
 * toggle()
 *
 * Notes:
 * show/hide: .zui-content is NOT in transition for performance reasons
 *
 * aria-hidden="true/false" is responsible for transition of the drawer
 * .close is responsible for margin of content
 * .hide is general visibility
 */
export default class Drawer {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.$currentTrigger = null; // triggering control element

    this.$effectTimeout = 300; // transition time + 50ms margin @see drawer.css

    this.$eventListenersAdded = false;

    this.$minDesktopWidth = 1200;

    this.$status = !(this.$el.classList.contains('hide') || this.mobileMode());

    this.$clickListener = event => this.onClick(event);
    this.$keydownListener = event => this.onKeydown(event);

    this.$el.zuiAriaStatus = (status, trigger) => this.status(status, trigger);

    this.$el.classList.add('updated');
  }

  addEventListeners() {
    if (!this.$eventListenersAdded) {
      document.addEventListener('click', this.$clickListener);
      document.addEventListener('keydown', this.$keydownListener);
      this.$eventListenersAdded = true;
    }
  }

  removeEventListeners() {
    document.removeEventListener('click', this.$clickListener);
    document.removeEventListener('keydown', this.$keydownListener);
    this.$eventListenersAdded = false;
  }

  /**
   * check if we are in DesktopMode
   *
   * @return {boolean}
   */
  desktopMode() {
    return window.matchMedia(`(min-width: ${this.$minDesktopWidth}px)`).matches;
  }

  /**
   * check if we are in MobileMode
   * @return {boolean}
   */
  mobileMode() {
    return !this.desktopMode();
  }

  /**
   * @param {MouseEvent} event
   */
  onClick(event) {
    // hide drawer in mobile mode in click outside
    if (this.mobileMode() && !this.$el.contains(event.target)) {
      this.hide();
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    if (['Esc', 'Escape'].indexOf(event.key) > -1) {
      if (this.mobileMode() || this.$el.contains(document.activeElement)) {
        this.hide();
      }
    } else
    // prevent tabbing out in mobileMode
    if (event.key === 'Tab' && this.mobileMode()) {

      const focusableElements = this.focusableElements();

      if (focusableElements.length < 2) {
        event.preventDefault();
      } else if (event.shiftKey && document.activeElement === focusableElements[0]) {
        event.preventDefault();
        focusableElements[focusableElements.length - 1].focus();
      } else if (!event.shiftKey && document.activeElement === focusableElements[focusableElements.length - 1]) {
        event.preventDefault();
        focusableElements[0].focus();
      }
    }
  }

  /**
   * show the drawer
   *
   * @param {HTMLElement} trigger
   * @param {boolean} noFocus
   */
  show(trigger = undefined, noFocus = undefined) {

    if (this.$status === true) return;

    this.$status = true;

    this.$currentTrigger = trigger || this.$currentTrigger || document.activeElement;

    // mark trigger as expanded
    if (this.$currentTrigger !== document.body) {
      this.$currentTrigger.setAttribute('aria-expanded', 'true');
    }

    // show the drawer (! display: none)
    this.$el.classList.remove('hide');

    // wait 100ms to ensure .hide is removed (required for transition)
    setTimeout(() => {
      // add effect class
      this.$el.classList.add('open');
      this.$el.setAttribute('aria-hidden', 'false');
      // remove effect class
      setTimeout(() => this.$el.classList.remove('open'), this.$effectTimeout);
      // add EventListeners delayed to avoid immediate click handling
      this.addEventListeners();
    }, 100);

    if (noFocus !== true) this.setFocus();

    zuiDispatchEvent(this.$el, 'zui:drawer:show', {
      $el: this.$el,
    });
  }

  /**
   * hide the drawer
   */
  hide() {

    if (this.$status === false) return;

    this.$status = false;

    // hide the drawer
    this.$el.setAttribute('aria-hidden', 'true');

    // add effect class
    this.$el.classList.add('close');

    setTimeout(() => {
      // hide (display: none)
      this.$el.classList.add('hide');
      // remove effect class
      this.$el.classList.remove('close');
    }, this.$effectTimeout);

    this.removeEventListeners();

    zuiDispatchEvent(this.$el, 'zui:drawer:hide', {
      $el: this.$el,
    });

    // mark all controls as aria-expanded="false"
    Array.from(document.querySelectorAll(`[aria-controls~="${zuiIdentify(this.$el)}"][aria-expanded="true"]`))
      .forEach(el => el.setAttribute('aria-expanded', 'false'));

    // focus trigger
    if (this.$currentTrigger) {
      this.$currentTrigger.focus();
      this.$currentTrigger = null;
    }
  }

  /**
   * get/set status (show/hide drawer)
   *
   * @param {boolean} status - set the status
   * @param {HTMLElement} trigger - Trigger Element
   *
   * @return {boolean}
   */
  status(status = undefined, trigger = undefined) {
    if (status === true) {
      this.show(trigger);
    } else if (status === false) {
      this.hide();
    }
    return this.$status;
  }

  /**
   * toggle status
   *
   * @return {boolean}
   */
  toggle(trigger = undefined) {
    this.status(!this.status(), trigger);
  }

  /**
   * focus configured element
   *
   * configurable via data-zui-focus attribute
   *
   * first => first focusable element
   * last => last focusable element
   * confirm => last element with data-zui-control="confirm"
   * dismiss => last element with data-zui-control="dismiss"
   * { string } => focus $el.querySelector(:scope {string})
   *
   */
  setFocus() {

    const focusableElements = this.focusableElements();
    const focusOption = this.$el.getAttribute('data-zui-focus');

    let tmpEl = null;

    if (focusableElements.length) {
      if (focusOption === 'first') {
        focusableElements[0].focus();
      } else if (focusOption === 'last') {
        focusableElements[focusableElements.length - 1].focus();
      } else if (focusOption === 'confirm' || focusOption === 'dismiss') {
        // find matching data-zui-control (reversed to prefer buttons in footer)
        tmpEl = focusableElements.reverse().find(el => el.getAttribute('data-zui-control') === focusOption);
        if (tmpEl) tmpEl.focus();
      } else if (focusOption) {
        tmpEl = document.querySelector(`#${zuiIdentify(this.$el)} ${focusOption}`);
        if (tmpEl) tmpEl.focus();
      } else {
        this.$el.focus();
      }
    }
  }

  /**
   * @return {HTMLElement[]}
   */
  focusableElements() {
    return Array.from(document.querySelectorAll([
      'button',
      '[href]',
      'input',
      'select',
      'textarea',
      '[role=button]',
      '[tabindex]:not([tabindex="-1"])',
    ]
      // apply :scope
      .map(t => `#${zuiIdentify(this.$el)} ${t}`).join(', ')))
      // filter invisible elements
      .filter(el => el.offsetParent !== null);
  }
}

/**
 * @param {HTMLElement} element
 * @param {Drawer} element.zuiDrawer
 * @constructor
 * @return Drawer;
 */
export function DrawerFactory(element) {
  if (element.zuiDrawer === undefined) {
    Object.defineProperty(element, 'zuiDrawer', {
      enumerable: false,
      writable: false,
      value: new Drawer(element),
    });
  }
  return element.zuiDrawer;
}
