/**
 * ZanduraUI Core
 *
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

// Managers
//----------
import DialogManager from './managers/dialog-manager';
import ScrollableParent from './helpers/scrollable-parent';
import SnackbarManager from './managers/snackbar-manager';

// Helpers
//---------
import zuiDispatchEvent from './helpers/dispatch-event';
import zuiIdentify from './helpers/identify';
import zuiTemplate from './helpers/template';

// Required Materials
//--------------------
// Molecules
import { ChipFactory } from './molecules/chip';
import { ChipsFactory } from './molecules/chips';
import { DisclosureFactory } from './molecules/disclosure';
import { MenuFactory } from './molecules/menu';
import { ProgressIndicatorFactory } from './molecules/progress-indicator';
import { StepperFactory } from './molecules/stepper';
import { TabsFactory } from './molecules/tabs';
import { TextfieldFactory } from './molecules/textfield';
import { ToggleButtonFactory } from './molecules/toggle-button';
import { ToggleButtonGroupFactory } from './molecules/toggle-button-group';

// Organisms
import { AutocompleteFactory } from './organisms/autocomplete';
import { AppBarFactory } from './organisms/app-bar';
import { CardFactory } from './organisms/card';
import { DataTableFactory } from './organisms/data-table';
import { DialogFactory } from './organisms/dialog';
import { DrawerFactory } from './organisms/drawer';
import { ListFactory } from './organisms/list';
import { ListItemFactory } from './organisms/list-item';

// Utils
import { AriaControlFactory } from './utils/aria-control';
import { OptionFactory } from './utils/option';
import { ListboxFactory } from './utils/listbox';
import ComponentFactory from './utils/component-factory';

// Global ZanduraUI Object
//-------------------------
const ZanduraUI = {

  // Managers
  //----------
  DialogManager: null,
  ScrollableParent: null,
  SnackbarManager: null,

  // Helpers
  //---------
  identify: zuiIdentify,
  dispatchEvent: zuiDispatchEvent,
  template: zuiTemplate,

  $autoInitSelectors: {
    // Molecules
    chips: ['.zui-chips[role="listbox"]'],
    menu: ['.zui-menu'],
    progressIndicator: [
      '.zui-progress-indicator[aria-valuenow]:not([data-zui-timing])',
      '.zui-progress-indicator[data-zui-timing]',
    ],
    tabs: ['.zui-tabs'],
    textfield: ['.zui-textfield'],
    // Organisms
    card: ['.zui-card'],
    drawer: ['.zui-drawer'],
    list: [
      '.zui-list[role="listbox"]',
      '.zui-list[role="menu"]',
    ],
    autocomplete: ['.zui-autocomplete'],
    // Utils
    ariaControl: ['[aria-controls]'],
    listBox: ['[role="listbox"]'],
  },

  /**
   * Initiate ZanduraUI
   */
  init() {

    // init managers on demand
    this.initDialogManager();
    this.initScollableParent();
    this.initSnackbarManager();

    // initiate required Components
    const componentKeys = Object.keys(this.$autoInitSelectors);
    for (let idx = 0; idx < componentKeys.length; idx++) {
      this.initFromSelectors(document, this.$autoInitSelectors[componentKeys[idx]]);
    }
  },

  /**
   * Initializes all components based on the given selectors starting at
   * the given $baseEl element (which can be document or any other arbitrary
   * element in the dom.
   *
   * @param {ParentNode} $baseEl
   * @param {Array} selectors
   */
  initFromSelectors($baseEl, selectors) {
    for(let idx = 0; idx < selectors.length; idx++) {
      const $els = Array.from(
        $baseEl.querySelectorAll(selectors[idx])
      );
      $els.forEach(element => ComponentFactory(element));
    }
  },

  /**
   * Initializes the menus in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initMenus($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.menu);
  },

  /**
   * Initializes the progress indicators in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initProgressIndicators($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.progressIndicator);
  },

  /**
   * Initializes the tabs in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initTabs($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.tabs);
  },

  /**
   * Initializes the textfields in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initTextfields($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.textfield);
  },

  /**
   * Initializes the cards in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initCards($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.card);
  },

  /**
   * Initializes the drawers in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initDrawers($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.drawer);
  },

  /**
   * Initializes the lists in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initLists($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.list);
  },

  /**
   * Initializes the ario-controls in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initAriaControls($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.ariaControl);
  },

  /**
   * Initializes the listboxes in $parentNode.
   *
   * @param {ParentNode} $parentNode
   */
  initListBoxes($parentNode) {
    this.initFromSelectors($parentNode, this.$autoInitSelectors.listBox);
  },

  /**
   * A helper method to initialize the SnackbarManager in case you omit the
   * ZanduraUI.init() method.
   *
   * @param {Boolean} override
   */
  initSnackbarManager(override = false) {
    if(this.SnackbarManager === null || override) {
      this.SnackbarManager = new SnackbarManager();
    }
  },

  /**
   * A helper method to initialize the ScrollableParent in case you omit the
   * ZanduraUI.init() method.
   *
   * @param {Boolean} override
   */
  initScollableParent(override = false) {
    if(this.ScrollableParent === null || override) {
      this.ScrollableParent = new ScrollableParent();
    }
  },

  /**
   * A helper method to initialize the DialogManager in case you omit the
   * ZanduraUI.init() method.
   *
   * @param {Boolean} override
   */
  initDialogManager(override = false) {
    if(this.DialogManager === null || override) {
      this.DialogManager = new DialogManager();
    }
  },

  // Molecules
  Chip: ChipFactory,
  Chips: ChipsFactory,
  Disclosure: DisclosureFactory,
  Menu: MenuFactory,
  ProgressIndicator: ProgressIndicatorFactory,
  Stepper: StepperFactory,
  Tabs: TabsFactory,
  Textfield: TextfieldFactory,
  ToggleButton: ToggleButtonFactory,
  ToggleButtonGroup: ToggleButtonGroupFactory,

  // Organisms
  AppBar: AppBarFactory,
  AutoComplete: AutocompleteFactory,
  Card: CardFactory,
  DataTable: DataTableFactory,
  Dialog: DialogFactory,
  Drawer: DrawerFactory,
  List: ListFactory,
  ListItem: ListItemFactory,

  // Utils
  AriaControl: AriaControlFactory,
  Listbox: ListboxFactory,
  Option: OptionFactory,
};

export default ZanduraUI;
