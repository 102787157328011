/**
 * zuiDispatchEvent()
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

/**
 * @param {HTMLElement} element
 * @param {string} eventName
 * @param {Object} detail
 * @param {boolean} bubbles
 * @param {boolean} cancelable
 */
export default function zuiDispatchEvent(element, eventName, detail = {}, bubbles = true, cancelable = true) {
  element.dispatchEvent(new CustomEvent(eventName, {
    bubbles,
    cancelable,
    detail,
  }));
}
