/**
 * ZanduraUI
 *
 * Build Entrypoint for
 *
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

// load required Polyfills
import '@babel/polyfill';
import '../polyfills/object-fit-cover/ofc';
import 'element-matches';
import CustomEvent from 'custom-event';

window.CustomEvent = CustomEvent;

import ZanduraUI from './ZanduraUI';
import domLoaded from './helpers/dom-loaded';

// Make ZanduraUI Namespace public available;
window.ZanduraUI = ZanduraUI;

window.ZanduraUICustomInit = window['zanduraUIManualInit'] || false;

domLoaded
  .then(() => {
    if(window['zanduraUIManualInit'] !== true) {
      ZanduraUI.init();
    }
  })
  .catch((e) => console.error('Failed to initiate ZanduraUI.', e));
