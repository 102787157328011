/**
 * ListItem
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { OptionFactory } from '../utils/option';
import Valueable from '../utils/valueable';
import { AriaControlFactory } from '../utils/aria-control';
import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';
/**
 * ListItem
 * ========
 */
export default class ListItem extends Valueable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    this.$actionElements = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .list-action, #${zuiIdentify(this.$el)} label.fluid.flex`))
      .filter((el) => {
        let parent = el;
        do parent = parent.parentElement;
        while (parent && !parent.classList.contains('item'));
        return parent === this.$el;
      });

    this.$el.addEventListener('click', event => this.onClick(event));

    if (this.ariaControl()) {
      this.$el.addEventListener('zui:option:toggle', (event) => {
        if (event.detail.status !== undefined) this.ariaControl().status(event.detail.status);
      });
    }

    this.$el.classList.add('updated');
  }

  focus() {
    const action = document.querySelector(`#${zuiIdentify(this.$el)} .list-action, #${zuiIdentify(this.$el)} label.fluid.flex`);
    if (action) action.focus();
  }

  onClick(event) {
    if (event.target.classList.contains('list-action')) {
      if (this.$el.getAttribute('role') === 'option') {
        OptionFactory(this.$el).toggle();
      }
      zuiDispatchEvent(this.$el, 'zui:list:click', {
        $el: this.$el,
        value: this.value(),
      });
    }
  }

  /**
   * @return {AriaControl}
   */
  ariaControl() {
    let idx;
    let controlElement;
    for (idx = 0; idx < this.$actionElements.length; idx += 1) {
      if (this.$actionElements[idx].hasAttribute('aria-controls')) {
        controlElement = this.$actionElements[idx];
        break;
      }
    }
    return controlElement ? AriaControlFactory(controlElement) : undefined;
  }

  ariaControls() {
    const controlElement = this.ariaControl();
    return controlElement
      ? document.getElementById(controlElement.getAttribute('aria-controls'))
      : undefined;
  }
}

/**
 * @param {HTMLElement} element
 * @constructor
 * @return ListItem
 */
export function ListItemFactory(element) {
  if (element.zuiListItem === undefined) {
    Object.defineProperty(element, 'zuiListItem', {
      enumerable: false,
      writable: false,
      value: new ListItem(element),
    });
  }
  return element.zuiListItem;
}
