/**
 * Stepper
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import SelectableList from '../utils/selectable-list';
import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Stepper
 * =======
 *
 * Provide methods for Stepper control
 *
 * Methods
 * -------
 * select({HTMLElement})
 */
export default class Stepper extends SelectableList {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    this.$itemSelector = `#${zuiIdentify(this.$el)} .step`;

    this.$eventPrefix = 'zui:stepper';

    this.$el.addEventListener('click', event => this.onClick(event));
    this.$el.classList.add('updated');
  }

  /**
   * @return {number}
   */
  get selectedIndex() {
    return this.getSteps().findIndex(item => this.isSelected(item));
  }

  /**
   * @return {HTMLElement}
   */
  get selectedStep() {
    return this.getStep(this.selectedIndex);
  }

  /**
   * @param {boolean} enabled
   * @param {boolean} selected
   * @return {HTMLElement[]}
   */
  getSteps(enabled = undefined, selected = undefined) {
    return super.getItems(enabled, selected);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  getStep(element) {
    return super.getItem(element);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  select(element) {
    return super.select(element);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  deselect(element) {
    return super.deselect(element);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {boolean}
   */
  isSelected(element) {
    return super.isSelected(element);
  }

  /**
   * @return {HTMLElement}
   */
  selectNext() {
    return this.select(Math.min(this.selectedIndex + 1, this.getSteps().length - 1));
  }

  /**
   * @return {HTMLElement}
   */
  selectPrevious() {
    this.select(Math.max(this.selectedIndex - 1, 0));
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  enable(element) {
    return super.enable(element);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  disable(element) {
    return super.disable(element);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {boolean|null}
   */
  isEnabled(element) {
    return super.isEnabled(element);
  }

  /**
   * @param {HTMLElement} element
   */
  markDone(element) {
    const item = this.getItem(element);

    if (item) {
      item.classList.add('done');
    }
  }

  /**
   * @param {HTMLElement} element
   */
  markUndone(element) {
    const item = this.getItem(element);

    if (item) {
      item.classList.remove('done');
    }
  }

  /**
   * @param {HTMLElement} element
   * @return {boolean|undefined}
   */
  isDone(element) {
    const item = this.getItem(element);
    return item ? item.classList.contains('done') : undefined;
  }

  /**
   * @param {MouseEvent} event
   */
  onClick(event) {
    const item = this.getItem(event.target);
    const control = event.target.matches('a, button') ? event.target : undefined;

    if (item && control && item.getAttribute('aria-disabled') !== 'true') {
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:click`, {
        $el: item,
        value: item.getAttribute('data-zui-value'),
        ariaControls: control ? control.getAttribute('aria-controls').split(' ') : undefined,
      });
    }
  }
}

/**
 * @param {HTMLElement} element
 * @param {Stepper} element.zuiStepper
 * @constructor
 * @return Chip;
 */
export function StepperFactory(element) {
  if (element.zuiStepper === undefined) {
    Object.defineProperty(element, 'zuiStepper', {
      enumerable: false,
      writable: false,
      value: new Stepper(element),
    });
  }
  return element.zuiStepper;
}
