/**
 * Chips
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { ChipFactory } from './chip';
import zuiIdentify from '../helpers/identify';

/**
 * Chips
 * =====
 *
 * + initiate .zui-chips .zui-chip
 *
 * @param {HTMLElement} element
 */
export default class Chips {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.initChips();

    this.$el.classList.add('updated');
  }

  initChips() {
    Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .zui-chip`))
      .forEach(el => ChipFactory(el));
  }
}

/**
 * @param {HTMLElement} element
 * @param {Chips} element.zuiChips  initiated Chips
 * @constructor
 * @return Chips
 */
export function ChipsFactory(element) {
  if (element.zuiChips === undefined) {
    Object.defineProperty(element, 'zuiChips', {
      enumerable: false,
      writable: false,
      value: new Chips(element),
    });
  } else {
    element.zuiChips.initChips();
  }
  return element.zuiChips;
}
