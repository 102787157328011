/**
 * ScrollableParent
 *
 * represents the global scrollable element
 * usually this is the document.body but may be it's another element (div[content], main, ...)
 */

import uuid from 'uuid/v4';

/**
 * ScrollableParent
 *
 * Lock/Unlock with different scopes
 *
 * example
 *
 * let a = lock() => locked (random / uuid-v4 scope)
 * let b = lock('foobar') => locked  (custom scope)
 * unlock(a) => locked  (because of b)
 * unlock(b) => unlocked (no more locked scopes)
 *
 * - set/get the element
 * - lock
 * - unlock
 */
export default class ScrollableParent {

  /**
   * @param element
   * @return {ScrollableParent}
   */
  constructor(element) {

    if (!ScrollableParent.instance) {

      this.$el = element || document.body;
      this.$lockClassName = 'scroll-lock';
      this.$scopes = [];

      ScrollableParent.instance = this;
    } else if (element !== undefined) {
      ScrollableParent.instance.setElement(element);
    }

    return ScrollableParent.instance;
  }

  /**
   * @param {HTMLElement} element
   */
  setElement(element) {
    this.$el = element;
  }

  /**
   * @return {HTMLElement}
   */
  getElement() {
    return this.$el;
  }

  /**
   * @param {string|null} scope
   * @return {string}
   */
  lock(scope) {

    const id = scope || uuid();
    const idx = this.$scopes.indexOf(id);

    // add if not existing
    if (idx === -1) this.$scopes.push(id);

    // add lock class
    this.$el.classList.add(this.$lockClassName);

    return id;
  }

  /**
   *
   * @param {string|null} scope
   * @return {boolean}
   */
  unlock(scope) {

    if (!scope) {
      // empty scopes
      this.$scopes = [];
    } else {
      // find and remove scope
      const idx = this.$scopes.indexOf(scope);
      if (idx >= 0) this.$scopes.splice(idx, 1);
    }

    if (this.$scopes.length <= 0) {
      this.$el.classList.remove(this.$lockClassName);
    }

    return this.status();
  }

  /**
   * @return {boolean} true if locked
   */
  status() {
    return this.$scopes.length > 0;
  }
}
