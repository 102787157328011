/**
 * Toggleable
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import Valueable from './valueable';
import zuiDispatchEvent from '../helpers/dispatch-event';

/**
 * Toggleable
 *
 * Methods
 * -------
 *
 * @see Valuable
 *
 * on() => set selected status to true; fire event zui:toggle
 *
 * off() => set selected status to false; fire event zui:toggle
 *
 * toggle() => toggle selected status (on > off | off > on)
 *
 * status() => get selected status (true/false)
 *
 * status({boolean}) => set selected status to {boolean} and return it
 *
 * Events
 * ------
 * zui:toggle { status: {boolean}, value: {string}|{boolean}}
 *
 * @param element
 */
export default class Toggleable extends Valueable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    this.$statusAttributeName = 'aria-selected';
    this.$onValue = 'true';
    this.$offValue = 'false';

    this.$eventPrefix = 'zui';
  }

  /**
   * turn on / select
   */
  on() {
    if (!this.status()) {
      if (this.$onValue) this.$el.setAttribute(this.$statusAttributeName, this.$onValue);
      else this.$el.removeAttribute(this.$statusAttributeName);

      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:toggle`, {
        $el: this.$el,
        status: true,
        value: this.value(),
      });
    }
  }

  /**
   * turn off / deselect
   */
  off() {
    if (this.status()) {
      if (this.$offValue) this.$el.setAttribute(this.$statusAttributeName, this.$offValue);
      else this.$el.removeAttribute(this.$statusAttributeName);

      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:toggle`, {
        $el: this.$el,
        status: false,
        value: this.value(),
      });
    }
  }

  /**
   * get/set status
   *
   * @param {boolean} status  status to set
   * @returns {boolean}
   */
  status(status = undefined) {
    // turn on
    if (status === true) this.on();
    // turn off
    else if (status === false) this.off();

    // RETURN current status
    return this.$onValue
      ? this.$el.getAttribute(this.$statusAttributeName) === this.$onValue
      : !this.$el.hasAttribute(this.$statusAttributeName);
  }

  /**
   * toggle status
   *
   * @returns {boolean}
   */
  toggle() {
    return this.status(!this.status());
  }
}
