/**
 * Tabs
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import SelectableList from '../utils/selectable-list';
import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Tabs
 * ====
 *
 *  + handle visibility of buttons
 *  + handle length and position of .indicator
 *  + handle scrolling
 *
 */
export default class Tabs extends SelectableList {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    const scrollButtons = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .zui-toggle-button`));

    if (!(scrollButtons.length === 2 || scrollButtons.length === 0)) {
      throw new Error('Invalid Markup for Tabs: Should have exactly two or none .zui-toggle-button.');
    }

    this.$itemSelector = `#${zuiIdentify(this.$el)} [role="tab"]`;
    this.$eventPrefix = 'zui:tabs';

    this.$multiselectable = false;
    this.$required = true;

    this.$tabList = document.querySelector(`#${zuiIdentify(this.$el)} [role="tablist"]`);
    this.$indicator = document.querySelector(`#${zuiIdentify(this.$el)} .indicator`);

    this.$leftButton = scrollButtons.length ? scrollButtons.shift() : null;
    this.$rightButton = scrollButtons.length ? scrollButtons.pop() : null;

    this.$animationFps = 1000 / 24;
    this.$animationDuration = 150; // ms

    this.$scrollInterval = null;

    this.$windowResizeThrottleTime = 100;
    this.$windowResizeInThrottle = false;

    this.$tabBarScrollThrottleTime = 100;
    this.$tabBarScrollInThrottle = false;

    this.initMutationObserver();
    this.addEventListeners();

    this.moveIndicator();
    this.handleButtonVisibility();

    window.addEventListener('resize', () => this.onWindowResize());
    this.$tabList.addEventListener('scroll', () => this.onTabBarScroll());

    this.handleButtonVisibility();

    this.setHeight();
    this.$el.classList.add('updated');
  }

  /**
   * @param {boolean} value
   * @return {boolean}
   */
  selectOnClick(value = undefined) {
    // set true
    if (value === true) this.$el.removeAttribute('data-zui-select-on-click');
    // set false
    else if (value === false) this.$el.removeAttribute('data-zui-select-on-click', 'false');
    // return value
    return this.$el.getAttribute('data-zui-select-on-click') !== 'false';
  }

  /**
   * set fixed height to hide the scrollbar
   */
  setHeight() {
    if (this.$tabList) {
      this.$el.style.height = null;
      this.$tabList.style.height = null;

      let height = this.getTabs()[0].offsetHeight;

      this.$el.style.height = height + "px";
      this.$tabList.style.height = height + 24 + "px";
      this.$indicator.style.top = height - 2 + "px";
    }
  }

  /**
   * move indicator and scroll tabs if aria-selected changes
   */
  initMutationObserver() {
    // observe mutations
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // handle change of aria-selected
        if (mutation.type === 'attributes'
          && mutation.attributeName === 'aria-selected'
          && mutation.target.getAttribute('aria-selected') === 'true'
        ) {
          // remove old aria-selected when new is set
          this.getTabs().filter(tab => this.isSelected(tab)).forEach((tab) => {
            if (tab !== mutation.target) {
              tab.removeAttribute('aria-selected');
            }
          });
          this.moveIndicator();
        }
      });
    });

    observer.observe(this.$tabList, { attributes: true, subtree: true });
  }

  addEventListeners() {
    // scroll on lft-button-click
    if (this.$leftButton) this.$leftButton.addEventListener('click', () => this.scrollLeft());
    // scroll on rgt-button-click
    if (this.$rightButton) this.$rightButton.addEventListener('click', () => this.scrollRight());
    // handle tab-click
    this.$el.addEventListener('click', event => this.onClick(event));
  }

  /**
   * @param {MouseEvent} event
   */
  onClick(event) {
    const tab = this.getTabs(true, false).find(el => (el === event.target || el.contains(event.target)));
    const control = tab ? document.querySelector(`#${zuiIdentify(tab)} [aria-controls]`) : undefined;

    if (tab) {
      if (this.selectOnClick()) this.select(tab);
      zuiDispatchEvent(this.$el, 'zui:tabs:click', {
        $el: tab,
        value: tab.getAttribute('data-zui-value'),
        ariaControls: control ? control.getAttribute('aria-controls').split(' ') : undefined,
      });
    }
  }

  scrollLeft() {
    // scroll to first tab out of viewport
    const tabs = this.getTabs();
    const listRight = this.$tabList.scrollLeft + this.$tabList.clientWidth;

    let tabRight;

    for (let idx = tabs.length - 1; idx >= 0; idx -= 1) {
      if (tabs[idx].offsetLeft < this.$tabList.scrollLeft) {
        tabRight = tabs[idx].offsetLeft + tabs[idx].clientWidth;
        this.scrollTabListTo(this.$tabList.scrollLeft - (listRight - tabRight));
        break;
      }
    }
  }

  scrollRight() {
    // scroll first tab out of viewport
    const tabs = this.getTabs();
    const listRight = this.$tabList.scrollLeft + this.$tabList.clientWidth;

    let tabRight;

    for (let idx = 0; idx < tabs.length; idx += 1) {
      tabRight = tabs[idx].offsetLeft + tabs[idx].clientWidth;
      if (tabRight > listRight) {
        this.scrollTabListTo(tabs[idx].offsetLeft);
        break;
      }
    }
  }

  /**
   * handle window.onresize event
   *
   * throttled to save computation time
   */
  onWindowResize() {

    if (!this.$windowResizeInThrottle) {

      this.moveIndicator(); // initial execution
      this.$windowResizeInThrottle = true;

      setTimeout(() => {
        this.setHeight();
        this.moveIndicator(); // final / throttled execution
        this.$windowResizeInThrottle = false;
      }, this.$windowResizeThrottleTime);
    }
  }

  onTabBarScroll() {

    if (!this.$tabBarScrollInThrottle) {

      this.handleButtonVisibility(); // initial execution
      this.$tabBarScrollInThrottle = true;

      setTimeout(() => {
        this.handleButtonVisibility(); // final / throttled execution
        this.$tabBarScrollInThrottle = false;
      }, this.$tabBarScrollThrottleTime);
    }
  }

  handleButtonVisibility() {

    const haveToScroll = this.$tabList.scrollWidth > this.$tabList.clientWidth;
    const scrollLeftMax = this.$tabList.scrollWidth - this.$tabList.clientWidth;

    if (this.$leftButton) {
      if (haveToScroll && this.$tabList.scrollLeft > 0) {
        this.$leftButton.setAttribute('aria-hidden', 'false');
      } else {
        this.$leftButton.setAttribute('aria-hidden', 'true');
      }
    }

    if (this.$rightButton) {
      if (haveToScroll && this.$tabList.scrollLeft < scrollLeftMax) {
        this.$rightButton.setAttribute('aria-hidden', 'false');
      } else {
        this.$rightButton.setAttribute('aria-hidden', 'true');
      }
    }
  }

  /**
   * move indicator to selected tab
   */
  moveIndicator() {

    const current = this.getTabs().find(tab => this.isSelected(tab));

    if (current) {
      this.$indicator.style.transform = `translateX(${current.offsetLeft}px) scale(${current.offsetWidth},1)`;

      // scroll to element if out of containers viewport
      const tabFrom = current.offsetLeft;
      const tabTo = tabFrom + current.clientWidth;
      const listFrom = this.$tabList.scrollLeft;
      const listTo = this.$tabList.clientWidth + this.$tabList.scrollLeft;

      if (tabFrom < listFrom) {
        this.scrollTabListTo(tabFrom);
      } else if (tabTo > listTo) {
        this.scrollTabListTo(tabTo - this.$tabList.clientWidth);
      }
      this.handleButtonVisibility();
    }
  }

  /**
   * @todo don't break if scrolling, instead change target
   *
   * @param {Number} scrollLeft
   */
  scrollTabListTo(scrollLeft) {

    const scrollTarget = Math.min(Math.max(0, scrollLeft), this.$tabList.scrollWidth - this.$tabList.clientWidth);

    // break if scrolling is already in progress
    if (this.$scrollInterval) {
      return;
    }

    // scroll immediately if not initialized
    if (!this.$el.classList.contains('updated')) {
      this.$tabList.scrollLeft = scrollTarget;
      this.handleButtonVisibility();
      return;
    }

    const pxPerTick = (Math.abs(scrollTarget - this.$tabList.scrollLeft) / this.$animationDuration) * this.$animationFps;

    this.$scrollInterval = setInterval(() => {
      if (scrollTarget > this.$tabList.scrollLeft) {
        this.$tabList.scrollLeft = Math.ceil(this.$tabList.scrollLeft + pxPerTick);
        if (this.$tabList.scrollLeft >= scrollTarget) {
          clearInterval(this.$scrollInterval);
          this.handleButtonVisibility();
          delete this.$scrollInterval;
        }
      } else {
        this.$tabList.scrollLeft = Math.floor(this.$tabList.scrollLeft - pxPerTick);
        if (this.$tabList.scrollLeft <= scrollTarget) {
          clearInterval(this.$scrollInterval);
          this.handleButtonVisibility();
          delete this.$scrollInterval;
        }
      }
    }, this.$animationFps);
  }

  /**
   * @param {boolean} enabled
   * @param {boolean} selected
   * @return {HTMLElement[]}
   */
  getTabs(enabled = undefined, selected = undefined) {
    return super.getItems(enabled, selected);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  getTab(element) {
    return super.getItem(element);
  }

  /**
   * @param {HTMLElement|number} element
   * @return {HTMLElement}
   */
  select(element) {
    return super.select(element);
  }
}

/**
 * @param {HTMLElement} element
 * @param {Tabs} element.zuiTabs
 * @constructor
 * @return Chip;
 */
export function TabsFactory(element) {
  if (element.zuiTabs === undefined) {
    Object.defineProperty(element, 'zuiTabs', {
      enumerable: false,
      writable: false,
      value: new Tabs(element),
    });
  }
  return element.zuiTabs;
}
