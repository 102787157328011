/**
 * List
 *
 * @package ZanduraUI
 * @author Johannes Pommranz <johannes.pommranz@zandura.net>
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { ListItemFactory } from './list-item';
import zuiIdentify from '../helpers/identify';

/**
 * List
 * ====
 *
 * + initiate .zui-list .item s
 * + Handle Arrow Keys
 *
 */
export default class List {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.$parentList = undefined;

    let parent = this.$el.parentElement;
    while (parent && !parent.classList.contains('zui-list')) parent = parent.parentElement;

    if (parent) this.$parentList = parent;

    if (this.isInteractive()) {
      // init items
      this.items().forEach(item => ListItemFactory(item));

      // add keydown handler only for topmost .zui-list
      if (!this.$parentList) {
        this.$el.addEventListener('keydown', event => this.onKeydown(event));
      }
    }

    this.$el.classList.add('updated');
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {

    switch (event.key) {
      case 'ArrowUp': case 'ArrowLeft':
        event.preventDefault();
        this.focusPreviousItem();
        break;
      case 'ArrowDown': case 'ArrowRight':
        event.preventDefault();
        this.focusNextItem();
        break;
      default:
        break;
    }
  }

  focusNextItem() {

    const items = this.focusableItems();
    const currentIndex = items
      .findIndex(item => item === document.activeElement || item.contains(document.activeElement));

    let nextIndex = currentIndex + 1;
    if (nextIndex >= items.length) nextIndex = 0;

    if (nextIndex >= 0 && items.length) {
      ListItemFactory(items[nextIndex]).focus();
    }
  }

  focusPreviousItem() {

    const items = this.focusableItems();
    const currentIndex = items
      .findIndex(item => item === document.activeElement || item.contains(document.activeElement));
    const prevIndex = (currentIndex || items.length) - 1;

    if (prevIndex >= 0 && items.length) {
      ListItemFactory(items[prevIndex]).focus();
    }
  }

  /**
   * get list items
   *
   * @return {HTMLElement[]}
   */
  items() {
    return Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .item`))
      // check if it's a direct child
      .filter((el) => {
        let parent = el;
        do parent = parent.parentElement;
        while (parent && !parent.classList.contains('zui-list'));
        return parent === this.$el;
      })
      // initialize Item
      .map(el => ListItemFactory(el).$el);
  }

  focusableItems() {
    return Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .item`))
      // filter .list-action
      .filter(el => document.querySelector(`#${zuiIdentify(el)} .list-action, #${zuiIdentify(el)} label.fluid.flex`))
      // filter invisible elements
      .filter(el => el.offsetParent !== null);
  }

  /**
   * @returns boolean
   */
  isInteractive() {
    return !!(['menu', 'listbox'].indexOf(this.$el.getAttribute('role')) >= 0);
  }
}

/**
 * @param {HTMLElement} element
 * @param {List} element.zuiList  initiated List
 * @constructor
 * @return List
 */
export function ListFactory(element) {
  if (element.zuiList === undefined) {
    Object.defineProperty(element, 'zuiList', {
      enumerable: false,
      writable: false,
      value: new List(element),
    });
  }
  return element.zuiList;
}
