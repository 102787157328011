/**
 * Disclosure
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import zuiDispatchEvent from '../helpers/dispatch-event';

/**
 * Disclosure
 * ==========
 *
 * + open/close an element
 *
 * @todo may be we should handle trigger element (focus on close)
 */
export default class Disclosure {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.$openClassName = 'open';
    this.$closedClassName = 'closed';

    this.$el.zuiAriaStatus = (status, trigger) => this.status(status, trigger);

    this.$el.classList.add('updated');
  }

  /**
   * Open the element
   */
  open() {
    this.$el.classList.remove(this.$closedClassName);
    this.$el.classList.add(this.$openClassName);
    zuiDispatchEvent(this.$el, 'zui:disclosure:toggle', {
      $el: this.$el,
      status: true,
    });
  }

  /**
   * close the element
   */
  close() {
    this.$el.classList.remove(this.$openClassName);
    this.$el.classList.add(this.$closedClassName);
    zuiDispatchEvent(this.$el, 'zui:disclosure:toggle', {
      $el: this.$el,
      status: false,
    });
  }

  /**
   * get and/or set the status (open/closed)
   *
   * @param {boolean} status
   * @return {boolean}
   */
  status(status = undefined) {
    // open
    if (status === true) this.open();
    // close
    else if (status === false) this.close();

    return this.$el.classList.contains(this.$openClassName) || !this.$el.classList.contains(this.$closedClassName);
  }

  /**
   * toggle the status on>off off>on
   *
   * @return {boolean}
   */
  toggle() {
    return this.status(!this.status());
  }
}

/**
 * @param {HTMLElement} element
 * @param {Disclosure} element.zuiDisclosure  initiated Disclosure
 * @constructor
 * @return Disclosure
 */
export function DisclosureFactory(element) {
  if (element.zuiDisclosure === undefined) {
    Object.defineProperty(element, 'zuiDisclosure', {
      enumerable: false,
      writable: false,
      value: new Disclosure(element),
    });
  }
  return element.zuiDisclosure;
}
