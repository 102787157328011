/**
 * ComponentFactory
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

// Molecules
import { ChipsFactory } from '../molecules/chips';
import { DisclosureFactory } from '../molecules/disclosure';
import { MenuFactory } from '../molecules/menu';
import { ProgressIndicatorFactory } from '../molecules/progress-indicator';
import { TabsFactory } from '../molecules/tabs';
import { TextfieldFactory } from '../molecules/textfield';
import { ToggleButtonFactory } from '../molecules/toggle-button';
import { ToggleButtonGroupFactory } from '../molecules/toggle-button-group';
// Organisms
import { AppBarFactory } from '../organisms/app-bar';
import { AutocompleteFactory } from '../organisms/autocomplete';
import { CardFactory } from '../organisms/card';
import { DataTableFactory } from '../organisms/data-table';
import { DialogFactory } from '../organisms/dialog';
import { DrawerFactory } from '../organisms/drawer';
import { ListFactory } from '../organisms/list';
// Utils
import { AriaControlFactory } from './aria-control';
import { ListboxFactory } from './listbox';

// Organisms

/**
 *
 * @param {HTMLElement} element
 * @constructor
 */
export default function ComponentFactory(element) {

  // Materials / Components
  //------------------------
  switch (true) {
    // Molecules
    case element.classList.contains('zui-chips'):
      ChipsFactory(element);
      break;
    case element.classList.contains('zui-disclosure'):
      DisclosureFactory(element);
      break;
    case element.classList.contains('zui-menu'):
      MenuFactory(element);
      break;
    case element.classList.contains('zui-progress-indicator'):
      ProgressIndicatorFactory(element);
      break;
    case element.classList.contains('zui-tabs'):
      TabsFactory(element);
      break;
    case element.classList.contains('zui-textfield'):
      TextfieldFactory(element);
      if(element.classList.contains('zui-autocomplete')) {
        AutocompleteFactory(element);
      }
      break;
    case element.classList.contains('zui-toggle-button'):
      ToggleButtonFactory(element);
      break;
    case element.classList.contains('zui-toggle-button-group'):
      ToggleButtonGroupFactory(element);
      break;
    // Organisms
    case element.classList.contains('zui-app-bar'):
      AppBarFactory(element);
      break;
    case element.classList.contains('zui-card'):
      CardFactory(element);
      break;
    case element.classList.contains('zui-data-table'):
      DataTableFactory(element);
      break;
    case element.classList.contains('zui-dialog'):
      DialogFactory(element);
      break;
    case element.classList.contains('zui-drawer'):
      DrawerFactory(element);
      break;
    case element.classList.contains('zui-list'):
      ListFactory(element);
      break;
    default:
      // ignore
      break;
  }

  // Utils
  //-------
  switch (true) {
    case element.hasAttribute('aria-controls'):
      AriaControlFactory(element);
      break;
    case element.getAttribute('role') === 'listbox':
      ListboxFactory(element);
      break;
    default:
      // ignore
      break;
  }

  return element;
}
