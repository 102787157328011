/**
 * Chip
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { OptionFactory } from '../utils/option';
import Valueable from '../utils/valueable';
import zuiDispatchEvent from '../helpers/dispatch-event';

/**
 * Option
 * ======
 *
 * Methods
 * -------
 *
 * @see Valueable
 *
 * remove() => remove the chip from DOM
 *
 * Events
 * ------
 * zui:chip:click { $el: {HTMLElement}, value: {string|bool|null}}
 * zui:chip:remove { $el: {HTMLElement}, value: {string|bool|null}}
 *
 * @param element
 */
export default class Chip extends Valueable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    this.$input = Array.from(this.$el.childNodes).find(node => node.tagName === 'INPUT');

    this.addEventListeners();
  }

  addEventListeners() {
    this.$el.addEventListener('click', event => this.onClick(event));
    this.$el.addEventListener('keydown', event => this.onKeydown(event));
    if (this.$input) this.$el.addEventListener('zui:option:toggle', event => this.onOptionToggle(event));
  }

  remove() {
    zuiDispatchEvent(this.$el, 'zui:chip:remove', {
      $el: this.$el,
      value: this.value(),
    });
    this.$el.parentElement.removeChild(this.$el);
  }

  /**
   * @param {Event} event
   */
  onClick(event) {
    // only if not disabled
    if (!this.$el.classList.contains('disabled') && !this.$el.hasAttribute('disabled')) {
      // click .chip-action
      if (event.target.classList.contains('chip-action')) {
        if (this.$el.getAttribute('role') === 'option') {
          OptionFactory(this.$el).toggle();
        }
        zuiDispatchEvent(this.$el, 'zui:chip:click', {
          $el: this.$el,
          value: this.value(),
        });
      } else
      // click remove button
      if (event.target.getAttribute('data-zui-control') === 'remove') {
        this.remove();
      }
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    if (['Spacebar', ' '].indexOf(event.key) > -1) {
      event.preventDefault();
      // remove
      if (event.target.getAttribute('data-zui-control') === 'remove') {
        this.remove();
      }
    }
  }

  /**
   * @param {CustomEvent} event
   */
  onOptionToggle(event) {
    if (this.$input) this.$input.checked = event.detail.status;
  }
}

/**
 * @param {HTMLElement} element
 * @param {Chip} element.zuiChip  initiated Chip
 * @constructor
 * @return Chip;
 */
export function ChipFactory(element) {
  if (element.zuiChip === undefined) {
    Object.defineProperty(element, 'zuiChip', {
      enumerable: false,
      writable: false,
      value: new Chip(element),
    });
  }
  return element.zuiChip;
}
