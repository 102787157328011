/**
 * Autocomplete
 *
 * @package ZanduraUI
 * @author Benjamin Ansbach <benjamin.ansbach@zandura.net>
 */

import { TextfieldFactory } from '../molecules/textfield';
import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiTemplate from '../helpers/template';
import zuiDebounce from '../helpers/debounce';
import zuiIdentify from "../helpers/identify";

/**
 *
 * @param {String} htmlText
 * @return {HTMLElement|ChildNode}
 */
function textToHTML(htmlText) {
  let template = document.createElement('template');
  htmlText = htmlText.trim();
  template.innerHTML = htmlText;
  return template.content.firstChild;
}

/**
 * Small helper function to read the integer value of an element.
 *
 * @param {HTMLElement} $element
 * @param {String} attribute
 * @param {Number} def
 * @return {Number}
 */
function readIntData($element, attribute, def) {
  const value = parseInt(readData($element, attribute, def), 10);
  if(Number.isNaN(value)) {
    return def;
  }

  return value;
}

/**
 * Reads a data attribute from the given element.
 *
 * @param {HTMLElement} $element
 * @param {String} attribute
 * @param {*} def
 * @return {*|string}
 */
function readData($element, attribute, def) {
  const value = $element.getAttribute('data-zui-' + attribute);
  if(value === null) {
    return def;
  }

  return value;
}

/**
 * Sets the given data attribute value.
 *
 * @param {HTMLElement} $element
 * @param {String} attribute
 * @param {*} value
 */
function setData($element, attribute, value) {
  $element.setAttribute('data-zui-' + attribute, value);
}

/**
 * Executes a safe querySelector call.
 *
 * @param {HTMLElement|Element|ChildNode} $el
 * @param {String} selector
 * @return {Element|null}
 */
function q($el, selector) {
  return document.querySelector(`#${zuiIdentify($el)} ${selector}`);
}

/**
 * Class that handles an autocomplete.
 */
export default class Autocomplete {

  /**
   * Initial data key in the cache object.
   *
   * @return {string}
   * @constructor
   */
  static get ZUI_INITIAL() {
    return '__zui_initial';
  }

  /**
   * Constructor.
   *
   * @param {HTMLElement} element
   */
  constructor(element) {
    // local cache for search results
    this.cache = {};

    // initial settings
    this.$el = element;

    this.isActive = false;

    // initialize settings, inputs and the zui menu
    this.initializeSettings();
    this.initializeInputs();
    this.initializeMenu();

    // the selected menu item index
    this.selectedMenuItemIndex = 0;

    // a progressbar (if there is any)
    this.$progress = q(this.$el, '[role="progressbar"]');
  }

  /**
   * Sets the search function that is called to gather the data.
   *
   * @param {Function} searchCallback
   * @return {Autocomplete}
   */
  setSearchCallback(searchCallback) {
    this.searchCallback = searchCallback;
    return this;
  }

  /**
   * Sets the initial data to be shown on focus without any input value.
   *
   * @param {Array} initialData
   * @return {Autocomplete}
   */
  changeInitialData(initialData) {
    this.addCache(Autocomplete.ZUI_INITIAL, initialData);
    return this;
  }

  /**
   * Sets the data so there will be less search callbacks.
   *
   * @param {Array} data
   * @return {Autocomplete}
   */
  changeData(data) {
    this.data = data;
    return this;
  }

  /**
   * Changes the max results setting.
   *
   * @param {Number|String} maxResults
   * @return {Autocomplete}
   */
  changeMaxResults(maxResults) {
    this.maxResults = Number.parseInt(maxResults.toString(), 10);
    if(Number.isNaN(this.maxResults)) {
      throw new Error("Invalid value for maxResults setting.");
    }
    return this;
  }

  /**
   * Changes the min search query length setting.
   *
   * @param {Number|String} minLength
   * @return {Autocomplete}
   */
  changeMinLength(minLength) {
    this.minLength = Number.parseInt(minLength.toString(), 10);
    if(Number.isNaN(this.maxResults)) {
      throw new Error("Invalid value for maxResults setting.");
    }
    return this;
  }

  /**
   * Changes the debounce in MS.
   *
   * @param {Number|String} debounceMS
   * @return {Autocomplete}
   */
  changeDebounce(debounceMS) {
    this.debounceMS = Number.parseInt(debounceMS.toString(), 10);
    if(Number.isNaN(this.maxResults)) {
      throw new Error("Invalid value for debounceMS setting.");
    }
    return this;
  }

  /**
   * Gets the input field.
   *
   * @return {HTMLElement|Element}
   */
  getInputEl() {
    return this.zuiTextfield.$input;
  }

  /**
   * Gets the hidden field.
   *
   * @return {HTMLElement|Element}
   */
  getHiddenEl() {
    return this.zuiTextfield.$hidden;
  }

  /**
   * Gets the hidden field.
   *
   * @return {HTMLElement|Element}
   */
  getMenuEl() {
    return this.zuiTextfield.$menu;
  }

  /**
   * Gets the menu instance.
   *
   * @return {Menu}
   */
  getMenu() {
    return this.getMenuEl().zuiMenu;
  }

  /**
   * Gets the result list element.
   *
   * @return {HTMLElement|Element}
   */
  getListEl() {
    return this.getMenu().$list;
  }

  /**
   * Reads and sets the settings from the elements data attributes.
   */
  initializeSettings() {
    // the maximum number of results to render
    this.changeMaxResults(readIntData(this.$el, 'max-results', 5));

    // the minimum length before a search is triggered
    this.changeMinLength(readIntData(this.$el, 'min-length', 2));

    // how long to wait for input strokes
    this.changeDebounce(readIntData(this.$el, 'debounce', 0));

    // initialize initial data
    const $initialEl = q(this.$el, 'script[type="application/json"].initial');
    if($initialEl !== null) {
      this.changeInitialData(JSON.parse($initialEl.innerHTML.trim()));
    }

    // initialize local data
    const $dataEl = q(this.$el, 'script[type="application/json"].data');
    this.data = null;
    if($dataEl !== null) {
      this.changeData(JSON.parse($dataEl.innerHTML.trim()));
    }
  }

  /**
   * Initialize the ZUI menu.
   */
  initializeMenu() {
    this.getMenuEl().addEventListener('zui:menu:click-action', (event) => this.handleMenuSelect(event));
    this.getMenuEl().addEventListener('zui:menu:close', () => this.isActive = false );
    this.listItemTemplate = q(this.getListEl(), 'script[type="text/template"]').innerHTML;
  }

  /**
   * Gets called automatically whenever the user selects an entry from the menu.
   *
   * @param {CustomEvent|Object} event
   */
  handleMenuSelect(event) {
    // update dom values
    this.getHiddenEl().value = event.detail.value;
    this.getInputEl().value = event.detail.$el.getAttribute('aria-label');

    // delegate event with additional key "display"
    zuiDispatchEvent(this.$el, 'zui:autocomplete:click-action', event.detail);
  }

  /**
   * Initializes the form inputs.
   */
  initializeInputs() {

    // just in case it's not initialized yet. Should happen automatically via init()
    // but if its added dynamically we'll need to manually init it. The factory
    // is idempotent, so not a problem
    this.zuiTextfield = TextfieldFactory(this.$el);

   // handlers
    if(this.debounceMS > 0) {
      this.keyUpHandler = zuiDebounce((event) => this.handleKeyUp(event), this.debounceMS);
    } else {
      this.keyUpHandler = (event) => this.handleKeyUp(event);
    }

    this.focusHandler = (event) => this.focus(event);
    this.keyDownHandler = (event) => this.handleKeyDown(event);

    this.getInputEl().addEventListener('keydown', this.keyDownHandler, true);
    this.getInputEl().addEventListener('keyup', this.keyUpHandler, true);
    this.getInputEl().addEventListener('focus', this.focusHandler, true);
    this.getInputEl().addEventListener('click', () => this.search());
  }

  /**
   *
   * @param {KeyboardEvent} event
   */
  handleKeyUp(event) {
    this.zuiTextfield.invalid(false);
    if(event.key !== 'Enter' && event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
      this.search();
    }
  }

  handleKeyDown(event) {
    if(this.handleNavigation(event.key) === true) {
      event.preventDefault();
      return false;
    }
  }

  handleNavigation(key) {
    const $list = this.getListEl();

    if($list.childNodes.length === 0) {
      return false;
    }

    let $select = null;
    if(key === 'Enter') {
      // a small trick because all custom fired events had the wrong target
      this.getMenu().onClick({
        target: q($list.childNodes[this.selectedMenuItemIndex], '.list-action')
      });
      return true;
    } else if(key === 'ArrowDown') {
      this.selectedMenuItemIndex++;
      if(this.selectedMenuItemIndex >= $list.childNodes.length) {
        this.selectedMenuItemIndex = 0;
      }
      $select = $list.childNodes[this.selectedMenuItemIndex];
    } else if(key === 'ArrowUp') {
      this.selectedMenuItemIndex--;
      if (this.selectedMenuItemIndex === -1) {
        this.selectedMenuItemIndex = $list.childNodes.length - 1;
      }
      $select = $list.childNodes[this.selectedMenuItemIndex];
    }

    if($select !== null) {
      let activeDescendant = this.getMenu().select($select);
      // transfer aria-activedescendant to input
      this.getInputEl().setAttribute('aria-activedescendant', activeDescendant);
      return true;
    }

    return false;
  }

  /**
   * As soon as the element gets a focus we will select the searchtext if there is any.
   * And, if there is any and it complies to the search rules, it will fire a search.
   *
   * If there is no text, we will display the initial data if available.
   */
  focus() {

    // only focus when it's not active
    // TODO: we keep the event bubbling so we will not cancel the event itself
    if(this.isActive === true) {
      return;
    }

    // at this point the input lost focus sometime in the past

    // we are active now!
    this.isActive = true;

    // check the length of the search input
    const query = this.getCurrentQuery();

    // if there is any input
    if (query.length > 0) {
      // select the whole text
      this.getInputEl().select();
    }

    this.search();
  }

  /**
   * Force the focus on the search element.
   */
  forceFocus() {
    if(document.activeElement !== this.getInputEl()) {
      this.isActive = true;
      this.getInputEl().focus();
    }
  }

  /**
   * Gets a value indicating whether the given query is valid.
   *
   * @param {String} query
   * @return {boolean}
   */
  isValidQuery(query) {
    // its valid when
    //  - its gteq min-length setting
    //   OR
    //  - length = 0 and we have initial data
    return query.length >= this.minLength ||
      (this.hasCache(Autocomplete.ZUI_INITIAL) && query.length === 0);
  }

  /**
   * Gets the current search query.
   *
   * @return {String}
   */
  getCurrentQuery() {
    return this.getInputEl().value.trim();
  }

  /**
   * Searches for the current query.
   */
  search() {
    const query = this.getCurrentQuery();

    // when the search is not valid, we will hide the menu if it's not already hidden
    // and leave this function
    if(!this.isValidQuery(query)) {
      if(this.getMenu().status()) {
        this.getMenu().close();
      }

      return;
    }

    // if there is locally cached data for the query we will use that
    if (this.hasCache(query)) {
      this.render(query);
      return;
    }

    // display initial data
    if (query === '' && this.hasCache(Autocomplete.ZUI_INITIAL)) {
      this.render(Autocomplete.ZUI_INITIAL);
      return;
    }

    this.progress(true);

    // first check if there is local data and search through this
    this.searchLocal(query)
      .then(([data, responseQuery]) => {
        // check if the result is valid, if so we will show that data.
        if (this.getCurrentQuery() === responseQuery && data.length > 0) {
          this.render(this.addCache(responseQuery, data));
          this.progress(false);
          return;
        }

        // seems not, so we will search remotely
        this.searchCallback && this.searchCallback(query)
          .then(([data, responseQuery]) => {
            if (this.getCurrentQuery() === responseQuery) {
              this.render(this.addCache(responseQuery, data));
            }
            this.progress(false);
          })
          .catch((error) => this.handleSearchError(error));
      })
      //.catch(this.handleSearchError); no rejection..

  }

  /**
   * Displays the search error.
   *
   * @param {String} error
   */
  handleSearchError(error) {
    this.zuiTextfield.invalid(error);
    this.progress(false);
    if(this.getMenu().status()) {
      this.getMenu().close();
    }
  }

  /**
   * Searches the local dataset if there is one. if not or any error occurs
   * it will return an empty dataset.
   *
   * @param {String} query
   * @param {Boolean} fullText
   */
  searchLocal(query, fullText = false) {
    return new Promise((resolve) => {
      if (this.data === null) {
        resolve([[], query]);
        return;
      }

      try {
        let results = [];
        this.data.forEach((item) => {
          let found = false;
          Object.keys(item).forEach((key) => {
            const v = item[key] !== null ? item[key].toString().toLowerCase() : '';
            if (fullText === false) {
              // simple search
              if (v.startsWith(query.toLowerCase())) {
                found = true;
              }
            } else {
              // fuzzy search
              if (v.indexOf(query.toLowerCase()) > -1) {
                found = true;
              }
            }
          });
          if (found === true) {
            results.push(item);
          }
        });

        if(results.length === 0 && fullText === false) {
          this.searchLocal(query, true)
            .then(([fuzzyResults, fuzzyQuery]) => resolve([fuzzyResults, fuzzyQuery]));
          return;
        }

        resolve([results, query]);
      }
      catch(e) {
        console.error(e);
        resolve([[], query]);
      }
    });
  }

  /**
   * Toggles the progress.
   *
   * @param {boolean} show
   */
  progress(show = false) {
    // animate wait progress if applicable
    if (this.$progress !== null) {
      if(show === true) {
        this.$progress.setAttribute('aria-hidden', 'false');
      } else {
        this.$progress.setAttribute('aria-hidden', 'true');
      }
    }
  }

  /**
   * Renders thew available results of the given query.
   *
   * @param {String} query
   */
  render(query) {
    this.progress(false);
    this.selectedMenuItemIndex = 0;

    if(!this.hasCache(query)) {
      this.addCache(query, []);
    }
    // we check if it's not rendered already
    if(readData(this.$el, 'query', null) !== query) {

      // cleanup the list container, loop the results, render, and append them
      // to the list container
      this.getListEl().innerHTML = '';
      let first = true;
      this.cache[query].forEach((item) => {
        const $html = textToHTML(zuiTemplate(this.listItemTemplate, item));
        this.getListEl().insertAdjacentElement('beforeend', $html);

        // override focus method of list action element
        q($html, '.list-action').focus = () => {
            console.log('foc!');
        };

        // stop the menu from setting the focus to prevent flickering on mobile
        q($html, '.list-action').addEventListener('focus', (e) => {
          e.preventDefault();
          return false;
        });

        if(first) {
          first = false;
          let activeDescendant = this.getMenu().select($html);
          this.getInputEl().setAttribute('aria-activedescendant', activeDescendant);
        }
      });
    }

    // save the current render state in the main element
    setData(this.$el, 'query', query);

    // show
    if(this.getListEl().innerHTML !== '') {
      if (!this.getMenu().status()) {
        this.getMenu().open(this.$el);
      }
    } else {
      this.getMenu().close();
    }

    // stop the animation
    this.progress(false);

    this.forceFocus();
  }

  /**
   * Adds an element to the cache and returns the cache key.
   *
   * @param {String} query
   * @param {Array} data
   * @return {String}
   */
  addCache(query, data) {
    this.cache[query] = data.slice(0, this.maxResults);
    return query;
  }

  /**
   * Gets a value indicating whether the cache key exists.
   *
   * @param {String} query
   * @return {boolean}
   */
  hasCache(query) {
    return this.cache[query] !== undefined;
  }

  /**
   * Shortcut to add an event listener.
   *
   * @param {String} type
   * @param {Function} listener
   */
  addEventListener(type, listener) {
    this.$el.addEventListener(type, listener);
  }
}

/**
 * Either creates a new AutoComplete instance or returns the existing one.
 *
 * @param {HTMLElement} element
 * @constructor
 * @return Autocomplete
 */
export function AutocompleteFactory(element) {
  if (element.zuiAutocomplete === undefined) {
    Object.defineProperty(element, 'zuiAutocomplete', {
      enumerable: false,
      writable: false,
      value: new Autocomplete(element),
    });
  }
  return element.zuiAutocomplete;
}
