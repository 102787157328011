/**
 * zuiTemplate()
 *
 * @package ZanduraUI
 * @author Benjamin Ansbach <benjamin.ansbach@zandura.net>
 */

/**
 * Simple templating based on placeholders.
 *
 * Placeholders are surrounded by '%%' to reduce conflicts with existing php
 * templating engines and reduce escaping. Nested fields can be specified by
 * joining the hierarchy with a dot.
 *
 * Object {
 *   name: {
 *     firstname: "Benjamin"
 *   },
 *   city: "Lübeck"
 * }
 *
 * Template:
 *  %%name.firstname%% aus %%city%%
 *
 * Idea from https://github.com/trix/nano
 *
 * @param {String} template
 * @param {Object} data
 * @returns {String}
 */
export default function zuiTemplate(template, data) {
  return template.replace(/%%([\w\.]+)%%/g, (ignore, key) => {
    const keys = key.split('.');
    let v = data[keys.shift()];

    for (let i = 0, l = keys.length; i < l; i++) {
      v = v[keys[i]];
    }

    return (v !== undefined && v !== null) ? v : '';
  });
}
