/**
 * DataTable
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 * @author Johannes Pommranz <johannes.pommranz@zandura.com>
 */

import zuiIdentify from '../helpers/identify';

/**
 * DataTable
 * =========
 *
 * + handle row selection
 * + handle Arrow keys for row focusing
 * + handle Spacebar on active row for de-/selecting
 *
 * Methods
 * -------
 * selectRow({HTMLElement}) select a row
 * deselectRow({HTMLElement}) deselect a row
 * selectAll() select all rows
 * deselectAll() deselect all rows
 *
 */
export default class DataTable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.$parentCheckbox = document.querySelector(`#${zuiIdentify(this.$el)} thead input[type=checkbox]`);

    this.addEventListeners();

    this.$el.classList.add('updated');
  }

  addEventListeners() {
    this.$el.addEventListener('click', event => this.onClick(event));
    this.$el.addEventListener('change', event => this.onChange(event));
    this.$el.addEventListener('keydown', event => this.onKeydown(event));
  }

  /**
   * @param {MouseEvent} event
   */
  onClick(event) {
    // select row on tr-click
    if (document.activeElement.nodeName === 'TR' && document.activeElement.contains(event.target)) {
      const checkbox = document.querySelector(`#${zuiIdentify(document.activeElement)} .select input[type=checkbox]`);
      if (checkbox && document.activeElement.getAttribute('aria-disabled') !== 'true') {
        if (checkbox.checked) this.deselectRow(document.activeElement);
        else this.selectRow(document.activeElement);
      }
    }
  }

  /**
   * @param {Event} event
   */
  onChange(event) {
    // onChange parentCheckbox
    if (event.target === this.$parentCheckbox) {
      if (this.$parentCheckbox.checked) this.selectAll();
      else this.deselectAll();
    } else
    // onChange row checkbox
    if (Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} tbody tr .select input[type=checkbox]`)).find(cb => cb === event.target)) {
      // find row
      let row = event.target.parentElement;
      while (row.nodeName !== 'TR') row = row.parentElement;

      // mark de-/selected
      if (event.target.checked) this.selectRow(row);
      else this.deselectRow(row);
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    const rows = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} tbody tr`));
    const activeRowIdx = rows.findIndex(row => row === document.activeElement);
    const activeRow = activeRowIdx >= 0 ? rows[activeRowIdx] : undefined;

    // handle Enter/Spacebar on active row
    if (activeRow && (event.key === ' ' || event.key === 'Spacebar')) {
      event.preventDefault();
      if (document.querySelector(`#${zuiIdentify(activeRow)} .select input[type=checkbox]`)) {
        if (activeRow.getAttribute('aria-selected') === 'true') {
          this.deselectRow(activeRow);
        } else {
          this.selectRow(activeRow);
        }
      }
    } else
    // handle ArrowUp
    if (activeRow && event.key === 'ArrowUp') {
      event.preventDefault();
      let prevIdx = activeRowIdx - 1;
      if (prevIdx < 0) prevIdx = rows.length - 1;
      while (rows[prevIdx].getAttribute('tabindex') === '-1' || !rows[prevIdx].hasAttribute('tabindex')) {
        prevIdx -= 1;
        if (prevIdx < 0) prevIdx = rows.length - 1;
      }
      rows[prevIdx].focus();
    } else
    // handle ArrowDown
    if (activeRow && event.key === 'ArrowDown') {
      event.preventDefault();
      let nextIdx = activeRowIdx + 1;
      if (nextIdx === rows.length) nextIdx = 0;
      while (rows[nextIdx].getAttribute('tabindex') === '-1' || !rows[nextIdx].hasAttribute('tabindex')) {
        nextIdx += 1;
        if (nextIdx === rows.length) nextIdx = 0;
      }
      rows[nextIdx].focus();
    }
  }

  /**
   * @param {HTMLElement} row
   */
  selectRow(row) {

    const checkbox = document.querySelector(`#${zuiIdentify(row)} .select input[type=checkbox]`);

    if (checkbox) {
      checkbox.checked = true;
      row.setAttribute('aria-selected', 'true');
    }

    this.handleParentCheckbox();
  }

  /**
   * @param {HTMLElement} row
   */
  deselectRow(row) {

    const checkbox = document.querySelector(`#${zuiIdentify(row)} .select input[type=checkbox]`);

    if (checkbox) {
      checkbox.checked = false;
      row.setAttribute('aria-selected', 'false');
    }

    this.handleParentCheckbox();
  }

  selectAll() {
    if (this.$parentCheckbox) {
      this.$parentCheckbox.checked = true;
      this.$parentCheckbox.setAttribute('aria-checked', 'true');
    }
    // select all rows
    Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} tbody tr`)).forEach(row => this.selectRow(row));
  }

  deselectAll() {
    if (this.$parentCheckbox) {
      this.$parentCheckbox.checked = false;
      this.$parentCheckbox.setAttribute('aria-checked', 'false');
    }
    // select all rows
    Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} tbody tr`)).forEach(row => this.deselectRow(row));
  }

  handleParentCheckbox() {
    if (this.$parentCheckbox) {
      const all = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} tbody tr .select input[type=checkbox]`)).length;
      const checked = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} tbody tr .select input[type=checkbox]:checked`)).length;

      if (checked === 0) {
        this.$parentCheckbox.checked = false;
        this.$parentCheckbox.indeterminate = false;
      } else if (all === checked) {
        this.$parentCheckbox.checked = true;
        this.$parentCheckbox.indeterminate = false;
      } else {
        this.$parentCheckbox.indeterminate = true;
        this.$parentCheckbox.setAttribute('aria-checked', 'mixed');
      }
      this.$parentCheckbox.setAttribute('aria-checked', this.$parentCheckbox.checked);
    }
  }
}

/**
 * @param {HTMLElement} element
 * @param {DataTable} element.zuiDataTable
 * @constructor
 * @return DataTable;
 */
export function DataTableFactory(element) {
  if (element.zuiDataTable === undefined) {
    Object.defineProperty(element, 'zuiDataTable', {
      enumerable: false,
      writable: false,
      value: new DataTable(element),
    });
  }
  return element.zuiDataTable;
}
