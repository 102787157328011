/**
 * zuiDomLoaded()
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

/**
 * @type {Promise}
 */
const zuiDomLoaded = new Promise((resolve) => {
  if (['interactive', 'complete'].indexOf(document.readyState) > -1) {
    resolve();
  } else {
    document.addEventListener('DOMContentLoaded', () => resolve(), {
      capture: true,
      once: true,
      passive: true,
    });
  }
});

export default zuiDomLoaded;
