/**
 * AppBar
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 * @author Johannes Pommranz <johannes.pommranz@zandura.com>
 */

import zuiIdentify from '../helpers/identify';

/**
 * AppBar
 * ======
 *
 * Methods
 * -------
 *
 * slideIn => slide the AppBar in (via class/css-transition)
 *
 * slideOut => slide the AppBar out (via class/css-transition)
 *
 */
export default class AppBar {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    const headlineSizes = [96, 60, 48, 34, 24, 20]; // represent headline-1 till headline-6 @see type-system.css
    const minHeadlineSize = headlineSizes[headlineSizes.length - 1];
    const minHeadlineDiff = headlineSizes[headlineSizes.length - 2] - headlineSizes[headlineSizes.length - 1];

    this.$el = element;
    this.$scrollTarget = this.$el.hasAttribute('data-zui-scrollable-parent')
      ? document.getElementById(this.$el.getAttribute('data-zui-scrollable-parent'))
      : window;
    this.$header = this.$el.parentElement;
    this.$title = document.querySelector(`#${zuiIdentify(this.$el)} .title.scale`);

    while (!this.$header.classList.contains('zui-header') && this.$header.parentElement) {
      this.$header = this.$header.parentElement;
    }

    this.$minAppBarHeight = 64;

    // calculate breakpoints
    this.$headlines = headlineSizes.map((size) => {
      const breakpoint = (this.$minAppBarHeight * ((size - minHeadlineSize) / minHeadlineDiff)) + this.$minAppBarHeight;
      return { size, breakpoint };
    });

    // detect current headline/breakpoint
    this.$breakpointIdx = this.$headlines.findIndex(headline => headline.breakpoint <= this.$header.offsetHeight);

    this.$lastScrollPos = this.$scrollTarget.scrollY || this.$scrollTarget.scrollTop || 0;
    this.$scrollDirection = null;

    this.addEventListeners();

    this.setScrollState(this.$lastScrollPos);

    this.$el.classList.add('updated');
  }

  addEventListeners() {

    const throttleTime = 1000 / 24;
    let scrollInThrottle = false;

    // throttled calculation
    this.$scrollTarget.addEventListener('scroll', () => {

      // not throttled
      this.onScroll();

      // throttled
      if (!scrollInThrottle) {
        scrollInThrottle = true;
        setTimeout(() => {
          this.onScrollThrottled();
          scrollInThrottle = false;
        }, throttleTime);
      }
    });
  }

  /**
   * scroll handler called immediately
   */
  onScroll() {

    const scrollPos = this.$scrollTarget.scrollY || this.$scrollTarget.scrollTop || 0;

    // detect scroll direction
    this.$scrollDirection = this.$lastScrollPos > scrollPos ? 'up' : 'down';
    this.$lastScrollPos = scrollPos;

    this.setScrollState(scrollPos);
  }

  /**
   * scroll handler called throttled (every 0,X seconds)
   */
  onScrollThrottled() {

    const scrollPos = this.$scrollTarget.scrollY || this.$scrollTarget.scrollTop || 0;

    if (this.$title) this.scaleTitle(scrollPos);

    this.handleSlide(scrollPos);
  }

  /**
   * add .scrolled, when header is invisible (under $minAppBarHeight)
   *
   * add .top when scrolled to top
   *
   * @var {int} scrollPos
   */
  setScrollState(scrollPos) {

    if ((this.$header.offsetHeight - this.$minAppBarHeight) <= scrollPos) {
      this.$el.classList.add('scrolled');
    } else {
      this.$el.classList.remove('scrolled');
      if (scrollPos <= 0) {
        this.$el.classList.add('top');
      } else {
        this.$el.classList.remove('top');
      }
    }
  }

  /**
   * scale the title size between initial size and min AppBar size
   *
   * @param {int} scrollPos
   */
  scaleTitle(scrollPos) {

    const visibleHeight = this.$header.offsetHeight - scrollPos;
    let minSize = 0;
    let ratio = 1;
    let scale = 1;

    if (scrollPos <= this.$header.offsetHeight && visibleHeight <= this.$el.offsetHeight) {
      minSize = this.$headlines[this.$headlines.length - 1].size;
      ratio = minSize / this.$headlines[this.$breakpointIdx].size;
      scale = Math.min(Math.max(ratio, 1 - (scrollPos / (this.$header.offsetHeight - this.$minAppBarHeight)) * (1 - ratio)), 1);
      this.$title.style.transform = `scale(${scale.toFixed(2)})`;
    }
  }

  /**
   * slide AppBAr in/out automatically,
   * when scrolled down at least 2 * header.height
   *
   * use these classes to activate automatic sliding
   * .scroll-down--slide-in
   * .scroll-down--slide-out
   * .scroll-up--slide-in
   * .scroll-up--slide-out
   *
   * @param {int} scrollPos
   */
  handleSlide(scrollPos) {
    if (scrollPos > this.$header.offsetHeight * 2) {
      if (this.$el.classList.contains(`scroll-${this.$scrollDirection}--slide-out`)) this.slideOut();
      if (this.$el.classList.contains(`scroll-${this.$scrollDirection}--slide-in`)) this.slideIn();
    }
  }

  /**
   * do slide in
   */
  slideIn() {
    this.$el.classList.remove('slided-out');
    this.$el.classList.add('slided-in');
  }

  /**
   * do slide out
   */
  slideOut() {
    this.$el.classList.remove('slided-in');
    this.$el.classList.add('slided-out');
  }
}

/**
 * @param {HTMLElement} element
 * @param {AppBar} element.zuiAppBar
 * @constructor
 * @return AppBar;
 */
export function AppBarFactory(element) {
  if (element.zuiAppBar === undefined) {
    Object.defineProperty(element, 'zuiAppBar', {
      enumerable: false,
      writable: false,
      value: new AppBar(element),
    });
  }
  return element.zuiAppBar;
}
