/**
 * Dialog
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 * @author Johannes Pommranz <johannes.pommranz@zandura.com>
 */

import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Dialog
 * ======
 *
 *  + handle scroll position of body (top/bottom divider)
 *  + handle click outside/dismissable
 *  + handle Escape Key
 *  + handle focus on open
 *  + handle focus not to leave Dialog
 *
 * Methods
 * -------
 * dismissable() check if Dialog is dismissable
 * dismissable({boolean}) set dismissable
 * show([{HTMLElement}]) show dialog and setup Triggering Element
 * hide() hide dialog
 * status() check if dialog is shown
 * update() update dialog (call when content changes)
 *
 * Events
 * ------
 *
 * zui:dialog:show
 * zui:dialog:hide
 * zui:dialog:dismiss
 * zui:dialog:confirm
 */
export default class Dialog {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.$el = element;

    this.$container = document.querySelector(`#${zuiIdentify(this.$el)} .zui-container`);

    this.$body = document.querySelector(`#${zuiIdentify(this.$el)} .zui-content`);

    this.$isModal = !this.$container.classList.contains('full-screen');

    this.$currentTrigger = null;

    this.$eventPrefix = 'zui:dialog';

    this.$checkDimensionsThrottleTime = 1000 / 10;
    this.$checkDimensionsInThrottle = false;

    this.$clickListener = event => this.onClick(event);
    this.$keydownListener = event => this.onKeydown(event);
    this.$resizeWindowListener = event => this.onResizeWindow(event);
    this.$scrollBodyListener = event => this.onScrollBody(event);

    this.$el.zuiAriaStatus = (status, trigger) => this.status(status, trigger);

    this.$el.classList.add('updated');
  }

  addEventListeners() {
    this.$el.addEventListener('click', this.$clickListener);
    window.addEventListener('keydown', this.$keydownListener);

    if (this.$isModal) {
      this.$body.addEventListener('scroll', this.$scrollBodyListener);
      window.addEventListener('resize', this.$resizeWindowListener);
    }
  }

  removeEventListeners() {
    this.$el.removeEventListener('click', this.$clickListener);
    window.removeEventListener('keydown', this.$keydownListener);

    if (this.$isModal) {
      this.$body.removeEventListener('scroll', this.$scrollBodyListener);
      window.removeEventListener('resize', this.$resizeWindowListener);
    }
  }

  /**
   * @param {Event} event
   */
  onClick(event) {
    const control = event.target.getAttribute('data-zui-control');

    if (control === 'confirm' || control === 'dismiss') {
      this.hide();
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:${control}`, {
        $el: this.$el,
      });
    } else if (!this.$container.contains(event.target) && this.dismissable()) {
      this.hide();
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:dismiss`, {
        $el: this.$el,
      });
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    if (['Esc', 'Escape'].indexOf(event.key) > -1 && this.dismissable()) {
      this.hide();
      zuiDispatchEvent(this.$el, 'zui:dialog:dismiss', {
        $el: this.$el,
      });
    } else if (event.key === 'Tab') {
      const focusableElements = this.focusableElements();

      if (focusableElements.length < 2) {
        event.preventDefault();
      } else if (event.shiftKey && document.activeElement === focusableElements[0]) {
        event.preventDefault();
        focusableElements[focusableElements.length - 1].focus();
      } else if (!event.shiftKey && document.activeElement === focusableElements[focusableElements.length - 1]) {
        event.preventDefault();
        focusableElements[0].focus();
      }
    }
  }

  /**
   * handle scroll on .zui-dialog .zui-content
   */
  onScrollBody() {
    this.checkDimensions();
  }

  /**
   * handle window resize
   */
  onResizeWindow() {
    this.checkDimensions();
  }

  /**
   * check the content and scroll position of the dialog and set top-/bottom-divider class
   *
   * throttled to reduce cpu usage
   */
  checkDimensions() {

    function check(body) {
      if (body.scrollTop > 0) {
        body.classList.add('top-divider');
      } else {
        body.classList.remove('top-divider');
      }

      if (body.scrollHeight > body.clientHeight && body.clientHeight + body.scrollTop !== body.scrollHeight) {
        body.classList.add('bottom-divider');
      } else {
        body.classList.remove('bottom-divider');
      }
    }

    if (!this.$checkDimensionsInThrottle) {
      this.$checkDimensionsInThrottle = true;
      check(this.$body); // initial call
      setTimeout(() => {
        this.$checkDimensionsInThrottle = false;
        check(this.$body); // last / throttled call
      }, this.$checkDimensionsThrottleTime);
    }
  }

  /**
   * focus configured element
   *
   * configurable via data-zui-focus attribute
   *
   * first => first focusable element
   * last => last focusable element
   * confirm => last element with data-zui-control="confirm"
   * dismiss => last element with data-zui-control="dismiss"
   * { string } => focus $el.querySelector(:scope {string})
   *
   */
  setFocus() {

    const focusableElements = this.focusableElements();
    const focusOption = this.$el.getAttribute('data-zui-focus');

    let tmpEl = null;

    if (focusableElements.length) {
      if (focusOption === 'first') {
        focusableElements[0].focus();
      } else if (focusOption === 'last') {
        focusableElements[focusableElements.length - 1].focus();
      } else if (focusOption === 'confirm' || focusOption === 'dismiss') {
        // find matching data-zui-control (reversed to prefer buttons in footer)
        tmpEl = focusableElements.reverse().find(el => el.getAttribute('data-zui-control') === focusOption);
        if (tmpEl) tmpEl.focus();
      } else if (focusOption) {
        tmpEl = document.querySelector(`#${zuiIdentify(this.$el)} ${focusOption}`);
        if (tmpEl) tmpEl.focus();
      } else {
        this.$el.focus();
      }
    }
  }

  /**
   * set and/or get dismissable status
   *
   * @param {boolean} dismissable
   * @return {boolean}
   */
  dismissable(dismissable = undefined) {
    // set dismissable
    if (dismissable === true) this.$el.setAttribute('data-zui-dismissable', 'true');
    // set not dismissable
    else if (dismissable === false) this.$el.setAttribute('data-zui-dismissable', 'false');

    return this.$el.getAttribute('data-zui-dismissable') !== 'false';
  }

  /**
   * show / open an dialog
   *
   * @param {HTMLElement} trigger - may be manually provided
   */
  show(trigger) {

    this.$currentTrigger = trigger || this.$currentTrigger || document.activeElement;

    this.$currentTrigger.setAttribute('aria-expanded', 'true');

    this.$el.classList.add('open');

    this.addEventListeners();

    zuiDispatchEvent(this.$el, `${this.$eventPrefix}:show`, {
      $el: this.$el,
    });

    this.setFocus();

    if (this.$isModal) {
      this.checkDimensions();
    }
  }

  /**
   * hide / close the dialog
   */
  hide() {

    this.$el.classList.remove('open');

    if (this.$currentTrigger) {
      this.$currentTrigger.setAttribute('aria-expanded', 'false');
    }

    this.removeEventListeners();

    zuiDispatchEvent(this.$el, `${this.$eventPrefix}:hide`, {
      $el: this.$el,
    });
  }

  /**
   * @param {boolean} status
   * @param {HTMLElement} trigger
   * @return {boolean}
   */
  status(status = undefined, trigger = undefined) {
    // show
    if (status === true) this.show(trigger);
    // hide
    else if (status === false) this.hide();

    return this.$el.classList.contains('open');
  }

  /**
   * tell the dialog that the content has updated
   */
  update() {
    this.checkDimensions();
  }

  /**
   * @return {HTMLElement[]}
   */
  focusableElements() {
    return Array.from(document.querySelectorAll([
      'button',
      '[href]',
      'input',
      'select',
      'textarea',
      '[role=button]',
      '[tabindex]:not([tabindex="-1"])',
      '.scroll', '.scroll-x', '.scroll-y', // overflow: auto/scroll makes divs focusable in FF
    ]
      // apply :scope
      .map(t => `#${zuiIdentify(this.$el)} ${t}`).join(', ')))
      // filter invisible elements
      .filter(el => el.offsetParent !== null);
  }
}

/**
 * @param {HTMLElement} element
 * @param {Dialog} element.zuiDialog
 * @constructor
 * @return Dialog;
 */
export function DialogFactory(element) {
  if (element.zuiDialog === undefined) {
    Object.defineProperty(element, 'zuiDialog', {
      enumerable: false,
      writable: false,
      value: new Dialog(element),
    });
  }
  return element.zuiDialog;
}
