/**
 * Valueable
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

/**
 * Valueable
 * =========
 *
 * Methods
 * -------
 *
 * value() => return value (regardless of selected status)
 *
 * value({string}) => set value to {string} and return it
 *
 */
export default class Valueable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.$el = element;

    this.$valueAttributeName = 'data-zui-value';
  }

  /**
   * get/set value
   *
   * @param {string} value
   * @returns {string}
   */
  value(value = undefined) {
    if (value !== undefined) {
      this.$el.setAttribute(this.$valueAttributeName, value);
    }
    return this.$el.getAttribute(this.$valueAttributeName);
  }
}
