/**
 * Listbox
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { OptionFactory } from './option';
import zuiDispatchEvent from '../helpers/dispatch-event';
import SelectableList from './selectable-list';
import zuiIdentify from '../helpers/identify';

/**
 * Listbox
 * =======
 *
 * + handles aria-multiselectable attribute
 *   => deselect last selected option when new option is selected
 *
 * + handles aria-required attribute
 *   => dis-/allow deselection of last selected element
 *
 * Methods
 * -------
 *
 * hasChanged() => return whether value has changed since last call or not
 *
 * isMultiSelectable() => get multiselectable property
 *
 * isMultiSelectable({boolean}) => set multiselectable property
 *
 * isRequired() => get required property
 *
 * isRequired({boolean}) => set required property
 *
 * options() => get array of HTMLElements ([role="option"])
 *
 * select({HTMLElement}) => select (turn on) an option by HTMLElement (or child)
 *
 * deselect({HTMLElement}) => deselect (turn off) an option by HTMLElement (or child)
 *
 * value() => get current value ( multiselectable ? array : string)
 *
 * @param {HTMLElement} element
 */
export default class Listbox extends SelectableList {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    // element shortcut
    super(element);

    this.$itemSelector = `#${zuiIdentify(this.$el)} [role="option"]`;
    this.$nestedCheckParentSelector = '[role="listbox"]';

    this.$eventPrefix = 'zui:listbox';

    // capture initial value
    this.lastValue = this.value();

    // initialize Options
    this.options();

    this.$el.addEventListener('zui:option:toggle', event => this.onZuiToggle(event));
  }

  /**
   * @param {Event} event
   */
  onZuiToggle(event) {

    const toggledOption = event.target;

    // handle option events only
    if (toggledOption.getAttribute('role') === 'option') {
      // check if it's my own option  (in case of nested listboxes)
      let parent = toggledOption.parentElement;
      while (parent && parent.getAttribute('role') !== 'listbox') parent = parent.parentElement;

      if (parent === this.$el) {
        // check multiselectable
        if (OptionFactory(toggledOption).status()) {
          // turn on
          if (!this.isMultiSelectable()) {
            // turn off anything else if not multiselectable
            this.options().forEach((opt) => {
              if (opt !== toggledOption && OptionFactory(opt).status()) {
                OptionFactory(opt).off();
              }
            });
          }
        } else {
          // turn off
          let checked = false;
          this.options().forEach((opt) => {
            if (opt !== toggledOption && OptionFactory(opt).status()) {
              checked = OptionFactory(opt).status();
            }
          });
          if (!checked && this.isRequired()) {
            OptionFactory(toggledOption).on();
          }
        }
      }
    }
    if (this.hasChanged()) {
      zuiDispatchEvent(this.$el, `${this.$eventPrefix}:change`, {
        $el: this.$el,
        value: this.lastValue,
      });
    }
  }

  /**
   * check if the value has changed since last check
   *
   * @return {boolean}
   */
  hasChanged() {
    const a = this.lastValue;
    const b = this.value();
    let equal = false;
    if (this.isMultiSelectable()) {
      equal = a.length === b.length && a.every((val, idx) => val === b[idx]);
    } else {
      equal = a === b;
    }
    this.lastValue = this.value();
    return !equal;
  }

  /**
   * @param {boolean} value
   * @return {boolean}
   */
  isMultiSelectable(value = undefined) {
    let multiSelectable = false;
    if (this.$el.getAttribute('role') === 'listbox') {
      if (value !== undefined) {
        this.$el.setAttribute('aria-multiselectable', value ? 'true' : 'false');
      }
      multiSelectable = this.$el.getAttribute('aria-multiselectable') === 'true';
    }
    return multiSelectable;
  }

  /**
   * @param {boolean} value
   * @return {boolean}
   */
  isRequired(value = undefined) {
    if (value !== undefined) {
      this.$el.setAttribute('aria-required', value ? 'true' : 'false');
    }
    return this.$el.getAttribute('aria-required') === 'true';
  }

  /**
   * @return {HTMLElement[]}
   */
  options(enabled = undefined, selected = undefined) {
    return super.getItems(enabled, selected)
      // initialize Option
      .map(el => OptionFactory(el).$el);
  }

  /**
   * @param {HTMLElement} element
   * @return {HTMLElement}
   */
  select(element) {
    const item = this.getItem(element);

    if (item) OptionFactory(item).on();

    return item;
  }

  /**
   * @param {HTMLElement} element
   * @return {HTMLElement}
   */
  deselect(element) {
    const item = this.getItem(element);

    if (item) OptionFactory(item).off();

    return item;
  }

  /**
   * get the current value
   * @return {string|string[]}
   */
  value() {
    const result = this.options().filter(el => OptionFactory(el).status()).map(el => OptionFactory(el).value());
    return this.isMultiSelectable() ? result : result[0] || null;
  }
}

/**
 * @param {HTMLElement} element
 * @param {Listbox} element.zuiListbox  initiated Listbox
 * @constructor
 * @return Listbox
 */
export function ListboxFactory(element) {
  if (element.zuiListbox === undefined) {
    Object.defineProperty(element, 'zuiListbox', {
      enumerable: false,
      writable: false,
      value: new Listbox(element),
    });
  }
  return element.zuiListbox;
}
