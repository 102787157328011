/**
 * zuiDebounce()
 *
 * @package ZanduraUI
 * @author Benjamin Ansbach <benjamin.ansbach@zandura.net>
 */

/**
 * Classic implementation of the debounce functionality.
 *
 * Example:
 * Element.addEventListener('click', debounce((event) => ..., 150);
 *
 * @param {Function} fn
 * @param {Number} waitMS
 * @returns {Function}
 */
export default function debounce(fn, waitMS) {
  let timeout;
  return function() {
    let context = this;
    let args = arguments;
    const later = () => {
      timeout = null;
      fn.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(() => later(), waitMS);
  };
}
