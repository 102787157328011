/**
 * DialogManager
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { DialogFactory } from '../organisms/dialog';
import zuiIdentify from '../helpers/identify';
import ScrollableParent from '../helpers/scrollable-parent';

/**
 * DialogManager
 * =============
 *
 * - handle visibility of Dialogs (one per time)
 * - handle dialog stack: reopen last not closed dialog
 * - handle aria-expanded and focus of triggering element ([aria-controls~="dialog-id"])
 *
 * Methods
 * -------
 *
 * showDialog({HTMLElement} element) => show an HTMLElement as dialog
 * hideDialog({HTMLElement} element) => hide an dialog by it's element
 * hideAll() => hide all Dialogs in stack
 *
 * Note
 * ----
 * This May turn into an DialogManager because Dialogs are SubClasses of Modals.
 * As long as Dialog is the only available Modal provided by ZanduraUI, it's to much overhead to implement
 * Modal>Dialog hierarchy.
 */
export default class DialogManager {

  /**
   * create singleton instance
   *
   * @return {*}
   */
  constructor() {

    if (!DialogManager.instance) {
      this.$modalStack = [];
      this.$muteEventListener = false;
      this.$scrollableParent = new ScrollableParent();

      window.addEventListener('zui:dialog:show', event => this.onShow(event));
      window.addEventListener('zui:dialog:hide', event => this.onHide(event));

      DialogManager.instance = this;
    }

    return DialogManager.instance;
  }

  /**
   * @param {Event} event
   */
  onShow(event) {

    if (this.$muteEventListener) return;

    const dialog = DialogFactory(event.detail.$el);

    // check if it is modal
    if (dialog.$isModal) {
      // find in stack
      const idx = this.$modalStack.indexOf(event.detail.$el);

      // hide other modals
      this.$muteEventListener = true;
      this.$modalStack.forEach((elm) => {
        if (event.detail.$el !== elm) this.hideElement(elm);
      });
      this.$muteEventListener = false;

      // remove from stack if already in
      if (idx > -1) this.$modalStack.splice(idx, 1);

      // add to stack as last element
      this.$modalStack.push(event.detail.$el);

      // lock scrollable parent
      this.$scrollableParent.lock('modal-dialog');
    } else {
      this.$scrollableParent.lock(zuiIdentify(dialog.$el));
    }
  }

  /**
   * @param {Event} event
   */
  onHide(event) {

    if (this.$muteEventListener) return;

    const dialog = DialogFactory(event.detail.$el);
    // check if it is modal
    if (dialog.$isModal) {
      // find in stack
      const idx = this.$modalStack.indexOf(event.detail.$el);

      // remove from stack
      if (idx !== -1) this.$modalStack.splice(idx, 1);

      // handle trigger/control element
      if (dialog.$currentTrigger) {
        dialog.$currentTrigger.setAttribute('aria-expanded', 'false');
        dialog.$currentTrigger.focus();
        dialog.$currentTrigger = undefined;
      }
      // make sure all controls for this dialog are aria-expanded=false
      Array.from(document.querySelectorAll(`[aria-controls~="${zuiIdentify(dialog.$el)}"]`))
        .forEach(ctrl => ctrl.setAttribute('aria-expanded', 'false'));

      this.$muteEventListener = true;
      if (this.$modalStack.length > 0) {
        // (re)show last in stack
        this.showElement(this.$modalStack[this.$modalStack.length - 1]);
      } else {
        this.$scrollableParent.unlock('modal-dialog');
      }

      this.$muteEventListener = false;
    } else {
      this.$scrollableParent.unlock(zuiIdentify(dialog.$el));
    }
  }

  /**
   * @param {HTMLElement} element
   */
  showElement(element) {
    DialogFactory(element).show();
  }

  /**
   * @param {HTMLElement} element
   */
  hideElement(element) {
    DialogFactory(element).hide();
  }

  hideAllModals() {
    this.$modalStack.forEach(element => this.hideElement(element));
  }
}
