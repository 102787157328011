/**
 * AriaControl
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import ComponentFactory from './component-factory';
import zuiDispatchEvent from '../helpers/dispatch-event';

/**
 * AriaControl
 * ===========
 *
 * + run zuiAriaStatus() on target element on click
 * + handle aria-expanded attribute
 */
export default class AriaControl {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    if (this.$el.getAttribute('data-zui-toggle-on-click') !== 'false') {
      this.$el.addEventListener('click', () => this.toggle());
    }

    // load initial status
    this.getTargets()
      .map(target => ComponentFactory(target)) // apply ComponentFactory
      .filter(target => typeof target.zuiAriaStatus === 'function')
      .forEach(target => this.$el.setAttribute('aria-expanded', target.zuiAriaStatus() ? 'true' : 'false'));
  }

  /**
   * @return {HTMLElement[]}
   */
  getTargets() {
    return this.$el.getAttribute('aria-controls').split(' ')
      .map(id => document.getElementById(id))
      .filter(elm => elm !== null);
  }

  /**
   * turn on
   */
  on() {
    // mark expanded true
    this.$el.setAttribute('aria-expanded', 'true');
    // call zuiAriaStatus() on all targets;
    this.getTargets()
      .map(target => ComponentFactory(target)) // apply ComponentFactory
      .filter(target => typeof target.zuiAriaStatus === 'function')
      .forEach(target => target.zuiAriaStatus(true, this.$el));
    // dispatch zui:toggle
    zuiDispatchEvent(this.$el, 'zui:aria-control:toggle', {
      $el: this.$el,
      status: true,
    });
  }

  /**
   * turn off
   */
  off() {
    // mark expanded false
    this.$el.setAttribute('aria-expanded', 'false');
    // call zuiAriaStatus() on all targets;
    this.getTargets()
      .map(target => ComponentFactory(target)) // apply ComponentFactory
      .filter(target => typeof target.zuiAriaStatus === 'function')
      .forEach(target => target.zuiAriaStatus(false, this.$el));
    // dispatch zui:toggle
    zuiDispatchEvent(this.$el, 'zui:aria-control:toggle', {
      $el: this.$el,
      status: false,
    });
  }

  /**
   * get and/or set status  on/off
   *
   * @param {boolean} status
   * @return {boolean}
   */
  status(status = undefined) {
    if (status === true) this.on();
    else if (status === false) this.off();

    return this.$el.getAttribute('aria-expanded') === 'true';
  }

  /**
   * @return {boolean}
   */
  toggle() {
    return this.status(!this.status());
  }
}

/**
 * @param {HTMLElement} element
 * @param {AriaControl} element.zuiAriaControl  initiated AriaControl
 * @constructor
 * @return AriaControl
 */
export function AriaControlFactory(element) {
  if (element.zuiAriaControl === undefined) {
    Object.defineProperty(element, 'zuiAriaControl', {
      enumerable: false,
      writable: false,
      value: new AriaControl(element),
    });
  }
  return element.zuiAriaControl;
}
