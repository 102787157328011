/**
 * Menu
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 * @author Johannes Pommranz <johannes.pommranz@zandura.net>
 */

import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Menu
 * ====
 *
 * Methods
 * -------
 *
 * open({HTMLElement} trigger) => show the menu
 *
 * close() => hide the menu
 *
 * status() => whether or not the menu is open
 *
 * status({boolean} status, {HTMLElement} trigger) => set the status
 *
 * toggle({HTMLElement} trigger) => flip status
 *
 * Events
 * ------
 *
 * zui:menu:open { $el: Element.zui-menu }
 *
 * zui:menu:close { $el: Element.zui-menu }
 *
 * zui:menu:action-click { value: <[data-zui-value]>, $el: Element[role="menuitem"] }
 *
 */
export default class Menu {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.$anchor = this.$el.classList.contains('anchor') ? this.$el : document.querySelector(`#${zuiIdentify(this.$el)} anchor`);
    this.$menuEl = document.querySelector(`#${zuiIdentify(this.$el)} [role="menu"]`);
    this.$list = document.querySelector(`#${zuiIdentify(this.$el)} .zui-list`);
    this.$controls = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} [aria-controls~="${zuiIdentify(this.$menuEl)}"]`));
    this.$currentTrigger = null;
    this.$currentEvent = null;

    this.$effectTimeout = 170; // transition time +50ms @see menu.css

    this.$clickListener = event => this.onClick(event);
    this.$keydownListener = event => this.onKeydown(event);

    this.$menuEl.zuiAriaStatus = (status, trigger) => this.status(status, trigger);

    this.$el.classList.add('updated');
  }

  /**
   * @param {boolean|null} disabled - true: only disabled, false: only enabled; else: anything
   * @return {HTMLElement[]}
   */
  getItems(disabled = null) {

    let items = Array.from(document.querySelectorAll(`#${zuiIdentify(this.$menuEl)} [role="menuitem"], #${zuiIdentify(this.$menuEl)} [role="option"]`));

    // only disabled items
    if (disabled === true) {
      items = items.filter(item => !!(item.disabled === true || item.getAttribute('aria-disabled') === 'true'));
    } else
    // only enabled items
    if (disabled === false) {
      items = items.filter(item => !(item.disabled === true || item.getAttribute('aria-disabled') === 'true'));
    }

    return items;
  }

  /**
   * @param {HTMLElement} trigger
   */
  open(trigger = undefined) {

    let focusEl;
    let listAction;
    let items;

    // remember the trigger (to be focused on close)
    this.$currentTrigger = trigger || this.$controls[0];

    // show and mark active
    this.$menuEl.setAttribute('aria-hidden', 'false');
    this.$menuEl.classList.add('active');

    if (this.$currentTrigger) {
      this.$currentTrigger.setAttribute('aria-expanded', 'true');
    }

    this.checkPosition();

    // focus an element
    //------------------

    // first selected
    focusEl = document.querySelector(`#${zuiIdentify(this.$menuEl)} [role="option"][aria-selected="true"]:not(.disabled)`);

    if (!focusEl) {
      items = this.getItems(false);
      // focus first element
      if (items.length > 0) {
        [focusEl] = items;
      }
    }

    // focus if found
    if (focusEl) {
      listAction = document.querySelector(`#${zuiIdentify(focusEl)} .list-action`);
      if (listAction) listAction.focus();
      else focusEl.focus();
    }

    // add Event Listeners
    setTimeout(() => {
      document.addEventListener('click', this.$clickListener);
      document.addEventListener('keydown', this.$keydownListener);
    }, 100); // delay, or it will be re-triggered

    // trigger ZuiEvent
    zuiDispatchEvent(this.$el, 'zui:menu:open', {
      $el: this.$el,
    });
  }

  /**
   * close the menu
   */
  close() {

    // hide and remove class active
    this.$menuEl.setAttribute('aria-hidden', 'true');
    setTimeout(() => this.$menuEl.classList.remove('active'), this.$effectTimeout);

    // remove listeners
    document.removeEventListener('click', this.$clickListener);
    document.removeEventListener('keydown', this.$keydownListener);

    // focus past trigger
    if (this.$currentTrigger) {
      this.$currentTrigger.setAttribute('aria-expanded', 'false');
      if (this.$currentEvent && this.$currentEvent !== undefined && this.$currentEvent.type === 'keydown') {
        // if we close on keydown, change focus after keyup (otherwise the trigger is triggered immediately)
        const keyup = () => {
          this.$currentTrigger.focus();
          document.removeEventListener('keyup', keyup);
        };
        document.addEventListener('keyup', keyup);
      } else {
        this.$currentTrigger.focus();
      }
    }

    // trigger ZuiEvent
    zuiDispatchEvent(this.$el, 'zui:menu:close', {
      $el: this.$el,
    });
  }

  /**
   * @param {boolean} status - set the status
   * @param {HTMLElement} trigger - Trigger Element
   * @return {boolean}
   */
  status(status = undefined, trigger = undefined) {
    if (status === true) {
      this.open(trigger || this.$controls[0]);
    } else if (status === false) {
      this.close();
    }
    return !(this.$menuEl.getAttribute('aria-hidden') !== 'false');
  }

  /**
   * @param {HTMLElement} trigger - Trigger Element
   * @return {boolean}
   */
  toggle(trigger = undefined) {
    return this.status(!this.status(), trigger);
  }

  /**
   * @param {HTMLElement} element
   * @return {String|null}
   */
  select(element) {
    let ariaActivedescendant = null;
    this.getItems(false).forEach((item) => {
      if (item === element || item.contains(element)) {
        item.setAttribute('aria-selected', 'true');
        ariaActivedescendant = zuiIdentify(item);
      } else {
        item.removeAttribute('aria-selected');
      }
    });

    if (ariaActivedescendant !== null) {
      this.$list.setAttribute('aria-activedescendant', ariaActivedescendant);
    } else {
      this.$list.removeAttribute('aria-activedescendant');
    }

    return ariaActivedescendant;
  }

  /**
   * check the position and set position--* class accordingly
   */
  checkPosition() {
    if (this.$anchor !== null) {
      const ao = this.$anchor.getBoundingClientRect();
      const ah = ao.height;
      const aw = ao.width;
      const ax = ao.x;
      const ay = ao.y;
      const vw = window.innerWidth;
      const vh = window.innerHeight;
      let px;
      let py;

      // X position
      if (ax + (aw / 2) < vw * 0.3333) px = 'left';
      else if (ax + (aw / 2) > vw * 0.6666) px = 'right';
      else px = 'center';

      // Y position
      if (this.$el.classList.contains('exposed')) py = 'top';
      else if (((ay * 100) / vh) < 33.33) py = 'top';
      else if ((ay + ah) * 100 / vh > 66.66) py = 'bottom';
      else py = 'center';

      // remove any position-- class
      Array.from(this.$menuEl.classList).forEach((name) => {
        if (name.startsWith('position--')) {
          this.$menuEl.classList.remove(name);
        }
      });

      // add calculated position-- class
      this.$menuEl.classList.add(`position--${px}-${py}`);
    }
  }

  /**
   * @param {MouseEvent|KeyboardEvent} event
   */
  onClick(event) {
    const menuItems = this.getItems(false); // enabled menu items

    let menuItem;
    let idx = 0;

    // find clicked, enabled menuItem
    while (idx <= menuItems.length && menuItems[idx] && !menuItems[idx].contains(event.target)) idx += 1;
    if (idx < menuItems.length) menuItem = menuItems[idx];

    // close if action is triggered or outside $menuEl
    if (menuItem || !this.$menuEl.contains(event.target)) {
      this.close(event);
    }

    if (menuItem) {
      zuiDispatchEvent(this.$el, 'zui:menu:click-action', {
        $el: menuItem,
        value: menuItem.getAttribute('data-zui-value'),
      });
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    // setup current event ( required in close() )
    this.$currentEvent = event;
    // handle Escape
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.preventDefault();
      this.close();
    } else
    // Handle Spacebar
    if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
      this.onClick(event);
    }
    this.$currentEvent = undefined;
  }
}

/**
 * @param {HTMLElement} element
 * @constructor
 * @return Menu
 */
export function MenuFactory(element) {
  if (element.zuiMenu === undefined) {
    Object.defineProperty(element, 'zuiMenu', {
      enumerable: false,
      writable: false,
      value: new Menu(element),
    });
  }
  return element.zuiMenu;
}
