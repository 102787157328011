/**
 * ZanduraUI.Textfield
 *
 * @package ZanduraUi
 * @author Falk Hermann <falk.hermann@zandura.com>
 * @author Johannes Pommranz <johannes.pommranz@zandura.com>
 *
 */

import { MenuFactory } from './menu';
import zuiDispatchEvent from '../helpers/dispatch-event';
import zuiIdentify from '../helpers/identify';

/**
 * Textfield
 * =========
 *
 *  + handle label floating
 *  + handle disabled flag
 *  + handle auto-resize of textarea
 *  + count chars in countable
 */
export default class Textfield {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    this.$el = element;

    this.$input = document.querySelector(
      ['input:not([type="hidden"])', 'select', 'textarea']
        .map(item => `#${zuiIdentify(this.$el)} ${item}`)
        .join(', ')
    );
    this.$hidden = this.$el.querySelector(`#${zuiIdentify(this.$el)} input[type="hidden"]`);

    this.$menu = document.querySelector(`#${zuiIdentify(this.$el)} .zui-menu`);

    this.$label = document.querySelector(`#${zuiIdentify(this.$el)} label`);

    this.$helper = this.$el.nextElementSibling && this.$el.nextElementSibling.hasAttribute('data-zui-helper')
      ? this.$el.nextElementSibling
      : null;

    this.$effectTimeout = 100;

    this.$windowResizeThrottleTime = 100;
    this.$windowResizeInThrottle = false;

    this.$lineHeight = parseInt(getComputedStyle(this.$input, null).lineHeight.slice(0, -2), 10);
    this.$minHeight = parseInt(this.$input.getAttribute('rows') || 1, 10) * this.$lineHeight;

    // cleanup disabled status
    this.disabled(this.disabled());

    this.initElements();
    this.initEventListeners();

    // handle things depending on value
    this.checkValue();

    // handle delayed (for browser-side auto-completion)
    setTimeout(() => this.checkValue(), 250);

    this.$el.classList.add('updated');
  }

  /**
   * initialize markup
   */
  initElements() {
    let hasIcon = false;
    // ensure class has-leading/trailing-icon
    if (document.querySelector(`#${zuiIdentify(this.$el)} .zui-icon.leading`)) {
      this.$el.classList.add('has-leading-icon');
      hasIcon = true;
    }
    if (document.querySelector(`#${zuiIdentify(this.$el)} .zui-icon.trailing`)) {
      this.$el.classList.add('has-trailing-icon');
      hasIcon = true;
    }

    if(hasIcon && this.$input.getAttribute('type') === 'date') {
      this.$el.classList.add('persistent-floating');
    }

    if (this.$label) {
      // ensure .has-label
      this.$el.classList.add('has-label');
      // ensure label markup
      if (this.$label.querySelector('.border') === null) {
        this.$label.innerHTML = `<span class="border"><span class="content">${this.$label.innerHTML}</span></span>`;
      }
      // ensure for attribute on label
      this.$label.setAttribute('for', zuiIdentify(this.$input));
    }

    // set min-height to multi-line textarea
    if (this.isMultiLineTextarea()) this.$input.style.minHeight = `${this.$minHeight}px`;

    // init .zui-menu
    if (this.$menu) this.initMenu();

    // ensure .input on input element
    this.$input.classList.add('input');
  }

  /**
   * add event listeners
   */
  initEventListeners() {

    const focusableElements = [this.$input];

    focusableElements.concat(Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .zui-icon`)))
      .forEach((elm) => {
        elm.addEventListener('focus', e => this.onFocus(e));
        elm.addEventListener('blur', e => this.onBlur(e));
      });

    this.$el.addEventListener('mouseover', e => this.onMouseover(e));
    this.$el.addEventListener('mouseout', e => this.onMouseout(e));
    this.$el.addEventListener('click', e => this.onClick(e));
    this.$el.addEventListener('keydown', e => this.onKeydown(e));

    if (this.isMultiLineTextarea()) {
      window.addEventListener('resize', () => this.onWindowResize());
    }

    this.$input.addEventListener('input', e => this.onInput(e));

    // input MutationObserver
    const observer = new MutationObserver(mutations => mutations.forEach((mutation) => {
      // handle change of disabled state
      if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
        if (this.$input.hasAttribute('disabled') && mutation.oldValue === null) {
          this.disabled(true);
        } else if (!this.$input.hasAttribute('disabled')) {
          this.disabled(false);
        }
      }
      // handle change of value
      if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
        this.checkValue();
      }
    }));
    observer.observe(this.$input, { attributes: true, attributeOldValue: true });
  }

  /**
   * initialize Menu functionality
   */
  initMenu() {

    const menu = MenuFactory(this.$menu);

    // handle initial value
    if (this.$hidden.value) {
      menu.getItems(false).forEach((item) => {
        if (item.getAttribute('data-zui-value') === this.$hidden.value) {
          const labelEl = document.querySelector(`#${zuiIdentify(item)} [aria-label]`) || item;
          const label = labelEl.getAttribute('aria-label') || labelEl.textContent;
          const disabled = this.disabled();

          menu.select(item);

          this.$input.setAttribute('disabled', 'true'); // trick out PW manager
          this.$input.value = label;
          if (!disabled) this.$input.removeAttribute('disabled');
        } else {
          item.removeAttribute('aria-selected');
        }
      });
    }

    // update value and label on menu-click
    this.$menu.addEventListener('zui:menu:click-action', (evt) => {
      const labelEl = evt.detail.$el.querySelector(`#${zuiIdentify(evt.detail.$el)} [aria-label]`) || evt.detail.$el;
      const disabled = this.disabled();
      let label = labelEl.getAttribute('aria-label') || labelEl.textContent;

      if (evt.detail.$el.hasAttribute('data-zui-none-option')) {
        this.$hidden.value = '';
        label = '';
      } else {
        this.$hidden.value = evt.detail.value;
      }

      this.$input.setAttribute('disabled', 'true'); // trick out PW manager
      this.$input.value = label;
      if (!disabled) this.$input.removeAttribute('disabled');

      // select clicked element
      menu.select(evt.detail.$el);
      this.checkValue();
    });
  }

  /**
   * add class has-value to input when value is not empty
   * add class floating to label when value is not empty and input is not focused
   */
  checkValue() {
    // handle .has-value
    if (this.$input) {
      if (!this.value()) {
        this.$el.classList.add('has-value');
      } else {
        this.$el.classList.remove('has-value');
      }
    }
    // handle label.floating
    if (this.$label) {
      if (!this.value() && this.$input !== document.activeElement &&
        !this.$el.classList.contains('persistent-floating'))
      {
        this.$label.classList.remove('floating');
      } else {
        this.$label.classList.add('floating');
      }
    }
    // handle none-value in Select
    if (this.$label && this.$input.nodeName === 'SELECT') {
      if (!this.$input.value) this.$input.selectedIndex = -1;
    }
    // handle counter
    if (this.hasCounter()) {
      const characterCount = `${this.value()}`.length;
      const maxLength = this.$input.getAttribute('maxlength') || null;
      this.$helper.innerHTML = `${characterCount}${maxLength ? `/${maxLength}` : ''}`;
    }
    // handle size of textarea.multi-line
    if (this.isMultiLineTextarea()) this.resizeTextarea();
  }

  /**
   * @param {Event} event
   */
  onFocus(event) {
    if (!this.$el.classList.contains('disabled') && this.$el.contains(event.target)) {

      this.$el.classList.add('focused', 'focus');

      if (this.$input === document.activeElement && !this.$input.hasAttribute('aria-controls')) {
        if (['INPUT', 'TEXTAREA'].indexOf(this.$input.nodeName) !== -1) {
          this.$el.classList.add('active');
          if (this.$label) {
            this.$label.classList.add('floating');
          }
        }
      }

      setTimeout(() => this.$el.classList.remove('focus'), this.$effectTimeout);
    }
  }

  /**
   * @param {Event} event
   */
  onBlur(event) {
    if (this.$input !== document.activeElement && this.$el.contains(event.target)) {
      this.$el.classList.remove('focused', 'active');
      this.checkValue();
    }
  }

  /**
   * @param {MouseEvent} event
   */
  onMouseover(event) {
    if (this.$el.contains(event.target)) this.$el.classList.add('hover');
  }

  /**
   * @param {MouseEvent} event
   */
  onMouseout(event) {
    if (this.$el.contains(event.target)) this.$el.classList.remove('hover');
  }

  /**
   * trigger clearInput / togglePassword on icon clicks
   * @param {MouseEvent} event
   */
  onClick(event) {
    // handle click on .zui-icon--cancel => clearInput
    if (event.target.classList.contains('zui-icon--cancel')) {
      this.clearInput();
    } else
    // handle click on .zui-icon--visibility => togglePassword
    if (event.target.classList.toString().includes('zui-icon--visibility')) {
      this.togglePassword();
    }
    // trigger event on .zui-icon click
    if (event.target.classList.contains('zui-icon')) {
      zuiDispatchEvent(this.$el, 'zui:textfield:click-icon', {
        $el: event.target,
        value: event.target.classList.toString().split('zui-icon--')[1].split(' ')[0],
      });
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {

    const el = document.activeElement;

    if (el.classList.contains('zui-icon') && (event.key === 'Enter' || event.key === ' ')) {
      // handle Enter/Space on .zui-icon--cancel => clearInput
      if (el.classList.contains('zui-icon--cancel')) {
        event.stopImmediatePropagation();
        this.clearInput();
      } else
      // handle Enter/Space on .zui-icon--visibility* => togglePassword
      if (event.target.classList.toString().includes('zui-icon--visibility')) {
        event.stopImmediatePropagation();
        this.togglePassword();
      }
      // trigger event on icon click
      if (el.classList.contains('zui-icon')) {
        zuiDispatchEvent(this.$el, 'zui:textfield:click-icon', {
          $el: el,
          value: event.target.classList.toString().split('zui-icon--')[1].split(' ')[0],
        });
      }
    }
  }

  /**
   * handle window.onresize event
   *
   * throttled to save computation time
   */
  onWindowResize() {

    if (!this.$windowResizeInThrottle) {

      this.resizeTextarea(); // initial execution
      this.$windowResizeInThrottle = true;

      setTimeout(() => {
        this.resizeTextarea(); // final / throttled execution
        this.$windowResizeInThrottle = false;
      }, this.$windowResizeThrottleTime);
    }
  }

  onInput() {
    this.checkValue();
  }

  /**
   * set and/or get disabled status
   *
   * .disabled() => get current disabled status
   * .disabled(bool) => set current disabled status to {boolean}
   *
   * @param {boolean} set
   * @return {boolean}
   */
  disabled(set = undefined) {
    // disable
    if (set === true) {
      this.$el.classList.add('disabled');
      this.$input.setAttribute('disabled', '');
      if (this.$menu) this.$menu.classList.add('disabled');
      // make icons inaccessible
      Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .zui-icon[tabindex]:not([tabindex="-1"])`))
        .forEach((icon) => {
          icon.setAttribute('data-zui-tabindex', icon.getAttribute('tabindex'));
          icon.setAttribute('tabindex', '-1');
        });
      this.$el.classList.remove('focused', 'active');
      this.$input.blur();
      this.checkValue();
    } else
    // enable
    if (set === false) {
      this.$el.classList.remove('disabled');
      this.$input.removeAttribute('disabled');
      if (this.$menu) this.$menu.classList.remove('disabled');
      // make icons accessible
      Array.from(document.querySelectorAll(`#${zuiIdentify(this.$el)} .zui-icon[data-zui-tabindex]`))
        .forEach((icon) => {
          icon.setAttribute('tabindex', icon.getAttribute('data-zui-tabindex'));
          icon.removeAttribute('data-zui-tabindex');
        });
    }
    // return current status
    return this.$el.classList.contains('disabled') || this.$input.hasAttribute('disabled');
  }

  /**
   * set and/or get invalid status
   *
   * .invalid() => get current invalid status
   * .invalid({boolean}) => set current invalid status to {boolean}
   * .invalid({string}) => set current invalid status to true and set message to {string}
   *
   * @param {boolean|string} set
   * @return {boolean}
   */
  invalid(set = undefined) {
    let invalid = this.$el.classList.contains('invalid');
    let message;

    // mark invalid
    if (set === true) {
      invalid = true;
      message = null;
    } else
    // mark valid
    if (set === false) {
      invalid = false;
      message = null;
    } else
    // mark in valid and set error message
    if (typeof set === 'string') {
      invalid = true;
      message = `${set}`;
    }

    // handle .invalid
    if (invalid) this.$el.classList.add('invalid');
    else this.$el.classList.remove('invalid');

    // handle [data-zui-error]
    if (message && this.$helper) this.$helper.setAttribute('data-zui-error', message);
    else if (this.$helper) this.$helper.removeAttribute('data-zui-error');

    return invalid;
  }

  /**
   * @return {boolean}
   */
  isMultiLineTextarea() {
    return this.$el.classList.contains('multi-line') && this.$input.nodeName === 'TEXTAREA';
  }

  /**
   * @return {boolean}
   */
  hasCounter() {
    return this.$el.classList.contains('counter') && this.$helper;
  }

  /**
   * auto resize textarea.multi-line
   */
  resizeTextarea() {
    if (this.isMultiLineTextarea()) {
      // freeze height of parent to prevent hopping
      const oHeight = this.$input.parentElement.style.height;
      this.$input.parentElement.style.height = `${this.$input.parentElement.clientHeight}px`;

      // set style by scrollHeight
      this.$input.style.height = `${this.$lineHeight}px`;
      this.$input.style.height = `${this.$input.scrollHeight}px`;

      // reset parent to oHeight
      this.$input.parentElement.style.height = oHeight;
    }
  }

  /**
   * clear input
   */
  clearInput() {
    this.$input.value = '';
    this.$input.focus();
    this.checkValue();
  }

  /**
   * toggle Password visibility
   */
  togglePassword() {

    const icon = this.$el.querySelector('.zui-icon--visibility, .zui-icon--visibility_off');

    if (this.$input.type === 'password') {
      this.$input.type = 'text';
    } else {
      this.$input.type = 'password';
    }

    if (icon) {
      icon.classList.toggle('zui-icon--visibility');
      icon.classList.toggle('zui-icon--visibility_off');
    }

    this.$input.focus();
  }

  /**
   * @return {string}
   */
  value() {
    return this.$input.value || '';
  }
}

/**
 * @param {HTMLElement} element
 * @param {Textfield} element.zuiTextfield
 * @constructor
 * @return Textfield;
 */
export function TextfieldFactory(element) {
  if (element.zuiTextfield === undefined) {
    Object.defineProperty(element, 'zuiTextfield', {
      enumerable: false,
      writable: false,
      value: new Textfield(element),
    });
  }
  return element.zuiTextfield;
}
