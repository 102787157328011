/**
 * SnackbarManager
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

/* eslint no-use-before-define: [1, 'nofunc'] */ // required for recursive calls of hideElement() and nextElement()

import zuiDispatchEvent from '../helpers/dispatch-event';

/**
 * SnackbarManager
 * ===============
 *
 * Methods
 * -------
 *
 * addElement({HTMLElement} element) adds an HTMLElement as SnackBar Element
 * addMessage({string} message) adds a string as simple SnackBar Element
 * hideElement({HTMLElement} element) hides an SnackBar Element and/or removes it from display queue
 *
 * @return {{addElement: addElement, addMessage: addMessage, hideElement: hideElement}}
 * @constructor
 */
export default class SnackbarManager {

  constructor() {

    if (!SnackbarManager.instance) {

      this.$queue = [];
      this.$zuiBottom = document.querySelector('.zui-bottom');

      this.$defaultTimeout = 3900;
      this.$reactionTime = 500;
      this.$readSpeed = 600;
      this.$minTimeout = 2000;

      this.$transitionTimeout = 300; // transition time +50ms @see snackbar.css

      this.$queueTimer = undefined;

      SnackbarManager.instance = this;
    }

    return SnackbarManager.instance;
  }

  /**
   * extract timeout
   *
   * read data-zui-timeout, or calculate time by string-length
   *
   * @param {HTMLElement} elm
   * @returns {number}
   */
  getTimeout(elm) {

    let timeout = this.$defaultTimeout;

    if (elm.getAttribute('data-zui-timeout') === 'auto') {
      const wordCount = elm.innerText.split(' ').length;
      timeout = Math.max(this.$minTimeout, (wordCount * this.$readSpeed) + this.$reactionTime);
    } else {
      timeout = parseInt(elm.getAttribute('data-zui-timeout'), 10);
      if (!timeout) timeout = this.$defaultTimeout;
    }
    return timeout;
  }

  /**
   * Display next Snackbar, hide after timeout
   */
  nextElement() {

    if (this.$queue.length < 1) return;

    const element = this.$queue[0];

    // make visible
    element.setAttribute('aria-hidden', 'false');

    setTimeout(() => {
      // slide in
      element.classList.add('active');
      zuiDispatchEvent(element, 'zui:snackbar:show');
    }, 50); // make sure zui-bottom is visible (through [aria-hidden="false"])

    // disabled timeout
    if (element.getAttribute('data-zui-timeout') === 'false') {
      this.$queueTimer = true;
      return;
    }

    // start timeout for hiding
    this.$queueTimer = setTimeout(() => {
      this.$queueTimer = null;
      this.hideElement(element);
    }, this.getTimeout(element));
  }

  /**
   * Hide Element and run nextElement() to display next in chain
   *
   * @param {HTMLElement} element
   */
  hideElement(element) {

    const queueIndex = this.$queue.findIndex(item => item === element);

    if (queueIndex > -1) {

      if (element.getAttribute('aria-hidden') !== 'false') {
        // not visible, just remove
        this.$queue.splice(queueIndex, 1);
      } else {
        // current visible

        // cancel running timer
        if (this.$queueTimer) clearTimeout(this.$queueTimer);

        // slide out
        element.classList.remove('active');

        // wait for transition
        this.$queueTimer = setTimeout(() => {
          this.$queueTimer = null;
          // make invisible
          element.setAttribute('aria-hidden', 'true');
          zuiDispatchEvent(element, 'zui:snackbar:hide');

          // remove from queue
          this.$queue.splice(queueIndex, 1);
          // remove from DOM
          if(element.getAttribute('data-dont-remove') === null) {
            if (element.parentElement) element.parentElement.removeChild(element);
          }
          // show next element // if any
          this.nextElement();
        }, this.$transitionTimeout);
      }
    }
  }

  /**
   * Enqueue a Snackbar and start display chain
   *
   * @param {HTMLElement} elm
   */
  enqueue(elm) {

    this.$queue.unshift(elm);

    // if queue isn't running, display nextElement
    if (!this.$queueTimer) this.nextElement();
  }

  /**
   * Add a DOM Element as Snackbar
   * and start displaying all elements in the queue
   *
   * @param {HTMLElement|string} snackbar
   * @return {HTMLElement|undefined}
   */
  addElement(snackbar) {

    let element;

    // try to find by id or selector
    if (typeof snackbar === 'string') {
      element = document.getElementById(snackbar) || document.querySelector(snackbar);
    } else
    // check if snackbar is a valid DOM Element
    if ((typeof snackbar === 'object')
      && (snackbar.nodeType === 1)
      && (typeof snackbar.style === 'object')
    ) {
      element = snackbar;
      element.classList.add('zui-snackbar');
    }

    // skip if not found
    if (!element) return;

    // skip of already enqueued
    if (this.$queue.find(itm => itm === element)) return;

    // append to .zui-bottom (if not already)
    this.$zuiBottom.appendChild(element);

    this.enqueue(element);

    return element;
  }

  /**
   * Create an .zui-snackbar element with a message
   * and start displaying all elements in the queue
   *
   * @param {string} message
   * @param {string} className
   * @param {string} position
   * @param {number} timeout
   *
   * @return {HTMLElement}
   */
  addMessage(message, className = undefined, position = undefined, timeout = undefined) {

    const element = document.createElement('div');

    element.classList.add('zui-snackbar');

    if (className) element.classList.add(className);

    if (position) element.classList.add(`position--${position}`);

    element.setAttribute('data-zui-timeout', (timeout === false ? 'false' : timeout) || 'auto');

    element.innerHTML = `<div>${message}</div>`;

    return this.addElement(element);
  }
}
