/**
 * ZanduraUI.ToggleButton
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import Toggleable from '../utils/toggleable';

/**
 * ToggleButton
 * ============
 *
 * Set the status of the button (aria-pressed="true|false")
 *
 * Methods
 * -------
 *
 * @see Toggleable
 *
 * Events
 * ------
 * zui:toggle-button:toggle { $el, status, value }
 *
 * @param {HTMLElement} element
 */
export default class ToggleButton extends Toggleable {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    this.$eventPrefix = 'zui:toggle-button';

    this.$statusAttributeName = 'aria-pressed';

    this.$el.addEventListener('click', () => this.toggle());

    this.$el.classList.add('updated');
  }
}

/**
 * Factory
 *
 * @param {HTMLElement} element
 * @param {ToggleButton} element.zuiToggleButton
 * @constructor
 * @return ToggleButton;
 */
export function ToggleButtonFactory(element) {
  if (element.zuiToggleButton === undefined) {
    Object.defineProperty(element, 'zuiToggleButton', {
      enumerable: false,
      writable: false,
      value: new ToggleButton(element),
    });
  }
  return element.zuiToggleButton;
}
