/**
 * ZanduraUI.ToggleButtonGroup
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

import { ListboxFactory } from '../utils/listbox';
import { OptionFactory } from '../utils/option';
import { ToggleButtonFactory } from './toggle-button';
import SelectableList from '../utils/selectable-list';
import zuiIdentify from '../helpers/identify';

/**
 * ToggleButtonGroup
 * =================
 *
 * + handle value- and status-sync between button and option
 *
 */
export default class ToggleButtonGroup extends SelectableList {

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {

    super(element);

    this.$itemSelector = `#${zuiIdentify(this.$el)} .zui-toggle-button`;

    this.$selectedAttribute = 'aria-pressed';

    this.$eventPrefix = 'zui:toggle-button-group';

    this.$listbox = ListboxFactory(this.$el);

    this.$listbox.options().forEach((elm) => {

      const option = OptionFactory(elm);
      const toggleButton = ToggleButtonFactory(document.querySelector(`#${zuiIdentify(elm)} .zui-toggle-button`));

      // synchronize value
      option.value(toggleButton.value());

      option.$el.addEventListener('zui:option:toggle', () => {
        toggleButton.status(option.status());
      });

      toggleButton.$el.addEventListener(`${this.$eventPrefix}:toggle`, (event) => {
        // don't bubble up, Option will do so
        event.stopPropagation();
        // synchronize value (may be changed)
        option.value(toggleButton.value());
        // synchronize status
        option.status(toggleButton.status());
      });
    });

    this.$el.classList.add('updated');
  }

  /**
   * get the current value
   * @return {string|string[]}
   */
  value() {
    return this.$listbox.value();
  }

  select(element) {
    super.select(element);
  }

  deselect(element) {
    super.deselect(element);
  }

  isSelected(element) {
    return super.isSelected(element);
  }

  enable(element) {
    return super.enable(element);
  }

  disable(element) {
    return super.disable(element);
  }

  isEnabled(element) {
    return super.isEnabled(element);
  }

  isMultiselectable(value = undefined) {
    return this.$listbox.isMultiSelectable(value);
  }

  isRequired(value = undefined) {
    return this.$listbox.isRequired(value);
  }
}

/**
 * @param {HTMLElement} element
 * @param {ToggleButtonGroup} element.zuiToggleButtonGroup  initiated ToggleButtonGroup
 * @constructor
 * @return ToggleButtonGroup
 */
export function ToggleButtonGroupFactory(element) {
  if (element.zuiToggleButtonGroup === undefined) {
    Object.defineProperty(element, 'zuiToggleButtonGroup', {
      enumerable: false,
      writable: false,
      value: new ToggleButtonGroup(element),
    });
  }
  return element.zuiToggleButtonGroup;
}
