/**
 * zuiIdentify()
 *
 * @package ZanduraUI
 * @author Falk Hermann <falk.hermann@zandura.net>
 */

/**
 * get the elements id attribute
 *
 * If the element has none, create a unique random id.
 *
 * @param {HTMLElement} element
 * @returns {string} id
 */
export default function zuiIdentify(element) {

  const charList = '0123456789abcdefghijklmnopqrstuvwxyz';

  let id = element.getAttribute('id');
  let rand = '';

  if (!id) {
    do {
      rand = '';
      for (let i = 0; i < 5; i += 1) {
        rand += charList.charAt(Math.floor(Math.random() * charList.length));
      }
      id = `zui-${rand}`;
    } while (document.getElementById(`${id}`));
    element.setAttribute('id', id);
  }

  return id;
}
